import React from "react";
import { ach, happy, idea, rating, process } from "../../assests/img/index";

const Aboutchoose = () => {
  return (
    <div>
      <div className="w-90 py-4">
        <div className="d-flex flex-wrap align-items-center mt-4 wh_us">
          <div className="col-md-4 wh_yzer">
            <h1>Why choose us?</h1>
            <p>Most important factor is finding a good solution.</p>
          </div>
          <div className="col-md-4 px-3 ch_os">
            <div className="col-md-12">
              <div className="cho_1">
                <div className="id_eas">
                  <img src={idea} alt="" />
                </div>
                <h6>We work for your ideas</h6>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <div className="cho_2">
                <div className="ex_p">
                  <img src={rating} alt="" />
                </div>
                <h6>Work with Domain Experts</h6>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <div className="cho_3">
                <div className="qu_al">
                  <img src={ach} alt="" />
                </div>
                <h6>Assured Quality & World Class Product</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-4 px-3 ch_os">
            <div className="col-md-12">
              <div className="cho_4">
                <div className="de_fi">
                  <img src={process} alt="" />
                </div>
                <h6>Process Defined Company</h6>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <div className="cho_5">
                <div className="ha_cus">
                  <img src={happy} alt="" />
                </div>
                <h6>Happy Customers</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutchoose;

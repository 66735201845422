import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
const HomeSeo = ({seo_page}) => {
    ReactGA.initialize('UA-250225495-1');
    ReactGA.initialize('G-54EZ0RNFEK');
    

    if(seo_page == "home"){
      ReactGA.pageview('/home');
      return (            
        <Helmet>
           <meta name="google-site-verification" content="fZNS7AAagtO7niFxzrtqd_LMZfKex6ZqDpYlpMUnldo" /> 

<title>Crm Development Company in Chennai, ERP Development Company in Chennai, Crm in Chennai, CRM Software Development Company in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai,  Best HRMS Software System in chennai, HR Payroll Software in Chennai, ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai</title>
            <meta name="description" content="We are one of the Best CRM & ERP Software Development company in Chennai. We provide Crm Development Company in Chennai, ERP Development Company in Chennai, Crm in Chennai, CRM Software Development Company in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai, Best HRMS Software System in chennai, HR Payroll Software in Chennai, ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai"/>
            <link rel="canonical" href="https://zercorp.com/" />
            <meta property="og:locale" content="en" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Crm Development Company in Chennai, ERP Development Company in Chennai, Crm in Chennai, CRM Software Development Company in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai,  Best HRMS Software System in chennai, HR Payroll Software in Chennai, ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai" />
            <meta property="og:description" content="We are one of the Best CRM & ERP Software Development company in Chennai. We provide Crm Development Company in Chennai, ERP Development Company in Chennai, Crm in Chennai, CRM Software Development Company in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai, Best HRMS Software System in chennai, HR Payroll Software in Chennai, ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai" />
            <meta property="og:url" content="https://zercorp.com/" />
            <meta property="og:image" content="https://zercorp.com/static/media/logo.c38decb0b4c385708847.png" />
            <meta property="fb:app_id" content="https://www.facebook.com/Zercorp-inc-102804135651058/" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="Zercorp" />
            <meta name="twitter:title" content="Crm Development Company in Chennai, ERP Development Company in Chennai, Crm in Chennai, CRM Software Development Company in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai,  Best HRMS Software System in chennai, HR Payroll Software in Chennai, ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai" />
            <meta name="twitter:description" content="We are one of the Best CRM & ERP Software Development company in Chennai. We provide Crm Development Company in Chennai, ERP Development Company in Chennai, Crm in Chennai, CRM Software Development Company in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai, Best HRMS Software System in chennai, HR Payroll Software in Chennai, ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai" />
            <meta name="twitter:image" content="https://zercorp.com/static/media/logo.c38decb0b4c385708847.png" />
            <meta name="twitter:image:alt" content="Zercorp" />

<script type="application/ld+json">
        {JSON.stringify({
            "@context":"http://schema.org",
            "@type": "WebPage",
            "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
            "description": "We are one of the Best CRM Software Development company in Chennai. We provide Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai, Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
            "url": "https://zercorp.com/"
        })}

    </script>
    <script type="application/ld+json">
        {JSON.stringify({
            "@context":"http://schema.org",
            "@type": "PostalAddress",
            "address": {
                "@type":"PostalAddress",
                "addressLocality": "D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani, Chennai 600 113.",
                "addressRegion": "India",
                "postalCode": "600113",
                "streetAddress": "D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani, Chennai 600 113."
        },
            
            "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
            "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday"
            ],
            "opens": "09:30",
            "closes": "06:30"
        },
            "telephone": "044-22548188",
            "url": "https://zercorp.com/"
        })}

    </script>
    <script type="application/ld+json">
        {JSON.stringify({
            "@context":"http://schema.org",
            "@type": "Organization",
            "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
            "url": "https://zercorp.com/",
            "sameAs": [
                "https://www.facebook.com/Zercorp-inc-102804135651058/",
                "https://www.linkedin.com/in/zercorp-software-b29508242/"
            ]
        })}

    </script>
  <script type="application/ld+json">
{JSON.stringify({
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://zercorp.com/"
  },
  "headline": "Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
  "description": "We are one of the Best CRM Software Development company in Chennai. We provide Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
  "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
  "author": {
    "@type": "Person",
    "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
    "logo": {
      "@type": "ImageObject",
      "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
    }
  },
  "datePublished": "2020-03-04",
  "dateModified": "2022-01-05"
    })}
</script>

<script type="application/ld+json">
{JSON.stringify({
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://zercorp.com/"
  },
  "headline": "Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
  "description": "We are one of the Best CRM Software Development company in Chennai. We provide Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
  "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
  "author": {
    "@type": "Organization",
    "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
    "logo": {
      "@type": "ImageObject",
      "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
    }
  },
  "datePublished": "2020-03-04",
  "dateModified": "2022-01-05"}
)}
</script>

<script type="application/ld+json">
{JSON.stringify({
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://zercorp.com/"
  },
  "headline": "Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennaig In Chennai",
  "description": "We are one of the Best CRM Software Development company in Chennai. We provide Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
  "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
  "author": {
    "@type": "Person",
    "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
    "logo": {
      "@type": "ImageObject",
      "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
    }
  },
  "datePublished": "2020-03-04",
  "dateModified": "2022-01-05"}
)}
</script>

<script type="application/ld+json">
{JSON.stringify({
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://zercorp.com/"
  },
  "headline": "Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
  "description": "We are one of the Best CRM Software Development company in Chennai. We provide Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
  "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
  "author": {
    "@type": "Organization",
    "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
    "logo": {
      "@type": "ImageObject",
      "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
    }
  },
  "datePublished": "2020-03-04",
  "dateModified": "2022-01-05"}
)}
</script>

<script type="application/ld+json">
{JSON.stringify({
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://zercorp.com/"
  },
  "headline": "Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
  "description": "We are one of the Best CRM Software Development company in Chennai. We provide Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
  "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
  "author": {
    "@type": "Person",
    "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
    "logo": {
      "@type": "ImageObject",
      "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
    }
  },
  "datePublished": "2020-03-04",
  "dateModified": "2022-01-05"}
)}
</script>

<script type="application/ld+json">
{JSON.stringify({
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://zercorp.com/"
  },
  "headline": "Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
  "description": "We are one of the Best CRM Software Development company in Chennai. We provide Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
  "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
  "author": {
    "@type": "Organization",
    "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
    "logo": {
      "@type": "ImageObject",
      "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
    }
  },
  "datePublished": "2020-03-04",
  "dateModified": "2022-01-05"}
)}
</script>
<script type="application/ld+json">
{JSON.stringify({
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
  "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
  "@id": "https://zercorp.com/",
  "url": "https://zercorp.com/",
  "telephone": "044-22548188",
  "priceRange": "min-1lakh",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani, Chennai 600 113.",
    "addressLocality": "Chennai",
    "postalCode": "600113",
    "addressCountry": "IN"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 12.800762,
  "longitude": 80.223476
  },
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ],
    "opens": "00:00",
    "closes": "23:59"
  } }
)}
</script>

<script type="application/ld+json">
{JSON.stringify({
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI PVT.LTD",
  "alternateName": "Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
  "url": "https://zercorp.com/",
  "logo": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
  "sameAs": [
    "https://www.facebook.com/Zercorp-inc-102804135651058/",
       "https://www.instagram.com/zercorp.software/",
    "https://www.linkedin.com/in/zercorp-software-b29508242/",
    "https://zercorp.com/"
  ]}
)}
</script>

 <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": "https://zercorp.com/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://query. zercorp.com /search?q={search_term_string}",
        "query-input": "required name=search_term_string"
      }}
    )}
    </script>

<script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://zercorp.com/",
      "logo": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"}
    )}
    </script>

<script type="application/ld+json">
{JSON.stringify({
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennaig In Chennai",
  "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
  "description": "We are one of the Best CRM Software Development company in Chennai. We provide Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
  "brand": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
  "sku": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
  "mpn": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
  "gtin14": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
  "gtin13": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
  "gtin8": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://zercorp.com/",
    "priceCurrency": "INR",
    "lowPrice": "100000",
    "highPrice": "100000000000",
    "offerCount": "1"
  },
     "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "worstRating": "1",
    "ratingCount": "20786"
  },
  "review": [
    {
      "@type": "Review",
      "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
      "datePublished": "2019-04-01",
      "reviewBody": "We are one of the Best CRM Software Development company in Chennai. We provide Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
      "author": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
      "reviewRating": {
        "@type": "Rating",
        "bestRating": "5",
        "ratingValue": "1",
        "worstRating": "1"
      }
    },
    {
      "@type": "Review",
      "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
      "datePublished": "2019-03-25",
      "reviewBody": "We are one of the Best CRM Software Development company in Chennai. We provide Crm Development Company in Chennai, Crm in Chennai,CRM Software Development Company in Chennai,CRM software in Chennai,Cloud CRM Software in Chennai,CRM Solution for All Business, Odoo Partner in Chennai, Best Odoo partners and resellers in Chennai",
      "author": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
      "reviewRating": {
        "@type": "Rating",
        "bestRating": "5",
        "ratingValue": "4",
        "worstRating": "1"
      }
    }
  ]}
)}
</script>

<script type="application/ld+json">
{JSON.stringify({
  "@context": "https://schema.org/",
  "@type": "WebSite",
  "name": "ZERCORP - CRM DEVELOPMENT COMPANY IN CHENNAI",
  "url": "https://zercorp.com/",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://zercorp.com/{search_term_string}",
    "query-input": "required name=search_term_string"
  }}
)}
</script>
</Helmet>
        
      ); 
    }
    else if(seo_page == "about"){
     ReactGA.pageview('/about');
          return (            
            <Helmet>
               <meta name="google-site-verification" content="fZNS7AAagtO7niFxzrtqd_LMZfKex6ZqDpYlpMUnldo" /> 
    
               <title>HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai</title>
               <meta name="description" content="Our HRMS simplifies every HR process to boost productivity and ensure efficient operations. HR Software in Chennai ✓ HRMS Software in Chennai ✓ Payroll Software Companies in Chennai. HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai" />
               <link rel="canonical" href="https://zercorp.com/about" />
               <meta property="og:locale" content="en" />
               <meta property="og:type" content="website" />
               <meta property="og:title" content="HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai" />
               <meta property="og:description" content="Our HRMS simplifies every HR process to boost productivity and ensure efficient operations. HR Software in Chennai ✓ HRMS Software in Chennai ✓ Payroll Software Companies in Chennai. HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai" />
               <meta property="og:url" content="https://zercorp.com/about" />
               <meta property="og:image" content="https://zercorp.com/static/media/logo.c38decb0b4c385708847.png" />
               <meta property="fb:app_id" content="https://www.facebook.com/Zercorp-inc-102804135651058/" />
               <meta name="twitter:card" content="summary" />
               <meta name="twitter:site" content="Zercorp" />
               <meta name="twitter:title" content="HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai" />
               <meta name="twitter:description" content="Our HRMS simplifies every HR process to boost productivity and ensure efficient operations. HR Software in Chennai ✓ HRMS Software in Chennai ✓ Payroll Software Companies in Chennai. HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai" />
               <meta name="twitter:image" content="https://zercorp.com/static/media/logo.c38decb0b4c385708847.png" />
               <meta name="twitter:image:alt" content="Zercorp" />
    
    <script type="application/ld+json">
            {JSON.stringify({
                "@context":"http://schema.org",
                "@type": "WebPage",
                "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
                "description": "Our HRMS simplifies every HR process to boost productivity and ensure efficient operations. HR Software in Chennai ✓ HRMS Software in Chennai ✓ Payroll Software Companies in Chennai. HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
                "url": "https://zercorp.com/about"
            })}
    
        </script>
        <script type="application/ld+json">
            {JSON.stringify({
                "@context":"http://schema.org",
                "@type": "PostalAddress",
                "address": {
                    "@type":"PostalAddress",
                    "addressLocality": "D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani, Chennai 600 113.",
                    "addressRegion": "India",
                    "postalCode": "600113",
                    "streetAddress": "D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani, Chennai 600 113."
            },
                
                "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
                "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday"
                ],
                "opens": "09:30",
                "closes": "06:30"
            },
                "telephone": "044-22548188",
                "url": "https://zercorp.com/about"
            })}
    
        </script>
        <script type="application/ld+json">
            {JSON.stringify({
                "@context":"http://schema.org",
                "@type": "Organization",
                "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
                "url": "https://zercorp.com/about",
                "sameAs": [
                    "https://www.facebook.com/Zercorp-inc-102804135651058/",
                    "https://www.linkedin.com/in/zercorp-software-b29508242/"
                ]
            })}
    
        </script>
      <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Article",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://zercorp.com/about"
      },
      "headline": "HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "description": "Our HRMS simplifies every HR process to boost productivity and ensure efficient operations. HR Software in Chennai ✓ HRMS Software in Chennai ✓ Payroll Software Companies in Chennai. HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
      "author": {
        "@type": "Person",
        "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
        "logo": {
          "@type": "ImageObject",
          "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
        }
      },
      "datePublished": "2020-03-04",
      "dateModified": "2022-01-05"
        })}
    </script>
    
    <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Article",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://zercorp.com/about"
      },
      "headline": "HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "description": "Our HRMS simplifies every HR process to boost productivity and ensure efficient operations. HR Software in Chennai ✓ HRMS Software in Chennai ✓ Payroll Software Companies in Chennai. HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
      "author": {
        "@type": "Organization",
        "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
        "logo": {
          "@type": "ImageObject",
          "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
        }
      },
      "datePublished": "2020-03-04",
      "dateModified": "2022-01-05"}
    )}
    </script>
    
    <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://zercorp.com/about"
      },
      "headline": "HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "description": "Our HRMS simplifies every HR process to boost productivity and ensure efficient operations. HR Software in Chennai ✓ HRMS Software in Chennai ✓ Payroll Software Companies in Chennai. HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
      "author": {
        "@type": "Person",
        "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
        "logo": {
          "@type": "ImageObject",
          "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
        }
      },
      "datePublished": "2020-03-04",
      "dateModified": "2022-01-05"}
    )}
    </script>
    
    <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://zercorp.com/about"
      },
      "headline": "HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "description": "Our HRMS simplifies every HR process to boost productivity and ensure efficient operations. HR Software in Chennai ✓ HRMS Software in Chennai ✓ Payroll Software Companies in Chennai. HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
      "author": {
        "@type": "Organization",
        "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
        "logo": {
          "@type": "ImageObject",
          "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
        }
      },
      "datePublished": "2020-03-04",
      "dateModified": "2022-01-05"}
    )}
    </script>
    
    <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://zercorp.com/about"
      },
      "headline": "HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "description": "Our HRMS simplifies every HR process to boost productivity and ensure efficient operations. HR Software in Chennai ✓ HRMS Software in Chennai ✓ Payroll Software Companies in Chennai. HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
      "author": {
        "@type": "Person",
        "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
        "logo": {
          "@type": "ImageObject",
          "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
        }
      },
      "datePublished": "2020-03-04",
      "dateModified": "2022-01-05"}
    )}
    </script>
    
    <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://zercorp.com/about"
      },
      "headline": "HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "description": "Our HRMS simplifies every HR process to boost productivity and ensure efficient operations. HR Software in Chennai ✓ HRMS Software in Chennai ✓ Payroll Software Companies in Chennai. HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
      "author": {
        "@type": "Organization",
        "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
        "logo": {
          "@type": "ImageObject",
          "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
        }
      },
      "datePublished": "2020-03-04",
      "dateModified": "2022-01-05"}
    )}
    </script>
    <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
      "@id": "https://zercorp.com/about",
      "url": "https://zercorp.com/about",
      "telephone": "044-22548188",
      "priceRange": "min-1lakh",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani, Chennai 600 113.",
        "addressLocality": "Chennai",
        "postalCode": "600113",
        "addressCountry": "IN"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": 12.800762,
        "longitude": 80.223476
      },
      "openingHoursSpecification": {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday"
        ],
        "opens": "00:00",
        "closes": "23:59"
      } }
    )}
    </script>
    
    <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI PVT.LTD",
      "alternateName": "HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "url": "https://zercorp.com/about",
      "logo": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
      "sameAs": [
        "https://www.facebook.com/Zercorp-inc-102804135651058/",
           "https://www.instagram.com/zercorp.software/",
        "https://www.linkedin.com/in/zercorp-software-b29508242/",
        "https://zercorp.com/about"
      ]}
    )}
    </script>
    
     <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": "https://zercorp.com/about",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://query. zercorp.com /search?q={search_term_string}",
            "query-input": "required name=search_term_string"
          }}
        )}
        </script>
    
    <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://zercorp.com/about",
          "logo": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"}
        )}
        </script>
    
    <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org/", 
      "@type": "Product", 
      "name": "HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
      "description": "Our HRMS simplifies every HR process to boost productivity and ensure efficient operations. HR Software in Chennai ✓ HRMS Software in Chennai ✓ Payroll Software Companies in Chennai. HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
      "brand": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
      "sku": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
      "mpn": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
      "gtin14": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
      "gtin13": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
      "gtin8": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
      "offers": {
        "@type": "AggregateOffer",
        "url": "https://zercorp.com/about",
        "priceCurrency": "INR",
        "lowPrice": "100000",
        "highPrice": "100000000000",
        "offerCount": "1"
      },
         "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": "20786"
      },
      "review": [
        {
          "@type": "Review",
          "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
          "datePublished": "2019-04-01",
          "reviewBody": "Our HRMS simplifies every HR process to boost productivity and ensure efficient operations. HR Software in Chennai ✓ HRMS Software in Chennai ✓ Payroll Software Companies in Chennai. HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
          "author": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
          "reviewRating": {
            "@type": "Rating",
            "bestRating": "5",
            "ratingValue": "1",
            "worstRating": "1"
          }
        },
        {
          "@type": "Review",
          "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
          "datePublished": "2019-03-25",
          "reviewBody": "Our HRMS simplifies every HR process to boost productivity and ensure efficient operations. HR Software in Chennai ✓ HRMS Software in Chennai ✓ Payroll Software Companies in Chennai. HRMS Software Development Company in Chennai,HR Software in Chennai, Payroll Software Providers in Chennai, Payroll Software Development Company in Chennai, HRMS Companies in Chennai",
          "author": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
          "reviewRating": {
            "@type": "Rating",
            "bestRating": "5",
            "ratingValue": "4",
            "worstRating": "1"
          }
        }
      ]}
    )}
    </script>
    
    <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "WebSite",
      "name": "ZERCORP - HRMS DEVELOPMENT COMPANY IN CHENNAI",
      "url": "https://zercorp.com/about",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://zercorp.com/{search_term_string}",
        "query-input": "required name=search_term_string"
      }}
    )}
    </script>
    </Helmet>
            
          ); 
        }
        else if(seo_page == "career"){
          ReactGA.pageview('/career');
               return (            
                 <Helmet>
                    <meta name="google-site-verification" content="fZNS7AAagtO7niFxzrtqd_LMZfKex6ZqDpYlpMUnldo" /> 
         
                    <title>Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai</title>
            <meta name="description" content="Zercorp Provides Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai" />
            <link rel="canonical" href="https://zercorp.com/career" />
            <meta property="og:locale" content="en" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai" />
            <meta property="og:description" content="Zercorp Provides Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai" />
            <meta property="og:url" content="https://zercorp.com/career" />
            <meta property="og:image" content="https://zercorp.com/static/media/logo.c38decb0b4c385708847.png" />
            <meta property="fb:app_id" content="https://www.facebook.com/Zercorp-inc-102804135651058/" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="Zercorp" />
            <meta name="twitter:title" content="Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai" />
            <meta name="twitter:description" content="Zercorp Provides Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai" />
            <meta name="twitter:image" content="https://zercorp.com/static/media/logo.c38decb0b4c385708847.png" />
            <meta name="twitter:image:alt" content="Zercorp" />
         
            <script type="application/ld+json">
              {JSON.stringify({
                  "@context":"http://schema.org",
                  "@type": "WebPage",
                  "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
                  "description": "Zercorp Provides Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
                  "url": "https://zercorp.com/career"
              })}
        
          </script>
          <script type="application/ld+json">
              {JSON.stringify({
                  "@context":"http://schema.org",
                  "@type": "PostalAddress",
                  "address": {
                      "@type":"PostalAddress",
                      "addressLocality": "D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani, Chennai 600 113.",
                      "addressRegion": "India",
                      "postalCode": "600113",
                      "streetAddress": "D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani, Chennai 600 113."
              },
                  
                  "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
                  "openingHoursSpecification": {
                  "@type": "OpeningHoursSpecification",
                  "dayOfWeek": [
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday"
                  ],
                  "opens": "09:30",
                  "closes": "06:30"
              },
                  "telephone": "044-22548188",
                  "url": "https://zercorp.com/career"
              })}
        
          </script>
          <script type="application/ld+json">
              {JSON.stringify({
                  "@context":"http://schema.org",
                  "@type": "Organization",
                  "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
                  "url": "https://zercorp.com/career",
                  "sameAs": [
                      "https://www.facebook.com/Zercorp-inc-102804135651058/",
                      "https://www.linkedin.com/in/zercorp-software-b29508242/"
                  ]
              })}
        
          </script>
        <script type="application/ld+json">
        {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Article",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://zercorp.com/career"
        },
        "headline": "Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "description": "Zercorp Provides Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
        "author": {
          "@type": "Person",
          "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI"
        },  
        "publisher": {
          "@type": "Organization",
          "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
          "logo": {
            "@type": "ImageObject",
            "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
          }
        },
        "datePublished": "2020-03-04",
        "dateModified": "2022-01-05"
          })}
        </script>
        
        <script type="application/ld+json">
        {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Article",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://zercorp.com/career"
        },
        "headline": "Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "description": "Zercorp Provides Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
        "author": {
          "@type": "Organization",
          "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI"
        },  
        "publisher": {
          "@type": "Organization",
          "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
          "logo": {
            "@type": "ImageObject",
            "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
          }
        },
        "datePublished": "2020-03-04",
        "dateModified": "2022-01-05"}
        )}
        </script>
        
        <script type="application/ld+json">
        {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://zercorp.com/career"
        },
        "headline": "Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "description": "Zercorp Provides Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
        "author": {
          "@type": "Person",
          "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI"
        },  
        "publisher": {
          "@type": "Organization",
          "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
          "logo": {
            "@type": "ImageObject",
            "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
          }
        },
        "datePublished": "2020-03-04",
        "dateModified": "2022-01-05"}
        )}
        </script>
        
        <script type="application/ld+json">
        {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://zercorp.com/career"
        },
        "headline": "Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "description": "Zercorp Provides Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
        "author": {
          "@type": "Organization",
          "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI"
        },  
        "publisher": {
          "@type": "Organization",
          "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
          "logo": {
            "@type": "ImageObject",
            "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
          }
        },
        "datePublished": "2020-03-04",
        "dateModified": "2022-01-05"}
        )}
        </script>
        
        <script type="application/ld+json">
        {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://zercorp.com/career"
        },
        "headline": "Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "description": "Zercorp Provides Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
        "author": {
          "@type": "Person",
          "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI"
        },  
        "publisher": {
          "@type": "Organization",
          "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
          "logo": {
            "@type": "ImageObject",
            "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
          }
        },
        "datePublished": "2020-03-04",
        "dateModified": "2022-01-05"}
        )}
        </script>
        
        <script type="application/ld+json">
        {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://zercorp.com/career"
        },
        "headline": "Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "description": "Zercorp Provides Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
        "author": {
          "@type": "Organization",
          "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI"
        },  
        "publisher": {
          "@type": "Organization",
          "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
          "logo": {
            "@type": "ImageObject",
            "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
          }
        },
        "datePublished": "2020-03-04",
        "dateModified": "2022-01-05"}
        )}
        </script>
        <script type="application/ld+json">
        {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
        "@id": "https://zercorp.com/career",
        "url": "https://zercorp.com/career",
        "telephone": "044-22548188",
        "priceRange": "min-1lakh",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani, Chennai 600 113.",
          "addressLocality": "Chennai",
          "postalCode": "600113",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": 12.800762,
          "longitude": 80.223476
        },
        "openingHoursSpecification": {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
          ],
          "opens": "00:00",
          "closes": "23:59"
        } }
        )}
        </script>
        
        <script type="application/ld+json">
        {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI PVT.LTD",
        "alternateName": "Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "url": "https://zercorp.com/career",
        "logo": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
        "sameAs": [
          "https://www.facebook.com/Zercorp-inc-102804135651058/",
             "https://www.instagram.com/zercorp.software/",
          "https://www.linkedin.com/in/zercorp-software-b29508242/",
          "https://zercorp.com/career"
        ]}
        )}
        </script>
        
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://zercorp.com/career",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://query. zercorp.com /search?q={search_term_string}",
              "query-input": "required name=search_term_string"
            }}
          )}
          </script>
        
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://zercorp.com/career",
            "logo": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"}
          )}
          </script>
        
        <script type="application/ld+json">
        {JSON.stringify({
        "@context": "https://schema.org/", 
        "@type": "Product", 
        "name": "Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
        "description": "Zercorp Provides Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
        "brand": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
        "sku": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
        "mpn": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
        "gtin14": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
        "gtin13": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
        "gtin8": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
        "offers": {
          "@type": "AggregateOffer",
          "url": "https://zercorp.com/career",
          "priceCurrency": "INR",
          "lowPrice": "100000",
          "highPrice": "100000000000",
          "offerCount": "1"
        },
           "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "5",
          "bestRating": "5",
          "worstRating": "1",
          "ratingCount": "20786"
        },
        "review": [
          {
            "@type": "Review",
            "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
            "datePublished": "2019-04-01",
            "reviewBody": "Zercorp Provides Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
            "author": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
            "reviewRating": {
              "@type": "Rating",
              "bestRating": "5",
              "ratingValue": "1",
              "worstRating": "1"
            }
          },
          {
            "@type": "Review",
            "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
            "datePublished": "2019-03-25",
            "reviewBody": "Zercorp Provides Accounting Software Development Company in Chennai, Custom Crm Development Services in Chennai, Retail Solution And Technologies, Report Generation Software in Chennai",
            "author": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
            "reviewRating": {
              "@type": "Rating",
              "bestRating": "5",
              "ratingValue": "4",
              "worstRating": "1"
            }
          }
        ]}
        )}
        </script>
        
        <script type="application/ld+json">
        {JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "ZERCORP - ACCOUNTING SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
        "url": "https://zercorp.com/career",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://zercorp.com/{search_term_string}",
          "query-input": "required name=search_term_string"
        }}
        )}
        </script>
        </Helmet>
              
            ); 
          }
          
           
    else{
      return;
    }
           
    
}

export default HomeSeo;
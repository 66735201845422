import React from "react";
import {
  android,
  apple,
  App_development,
  cloud,
} from "../../../assests/img/index";

const Appdservices = () => {
  return (
    <div>
      <div className="w-90">
        <div className="d-flex flex-wrap align-items-center zer_vis">
          <div className="col-md-6 mt-4 d-md-none d-block">
            <div className="vis_img">
              <img src={App_development} alt="" />
            </div>
          </div>
          <div className="col-md-6 px-2">
            <div className="vi_si">
              <h1>Robust Application</h1>
              <div className="po_4 mt-2">
                <p>
                  Whether you're aiming for operational excellence or improved
                  customer experiences augmented mixed or virtual realities can
                  make the difference.
                </p>
              </div>
            </div>
            <div className="pro_ser">
              <div className="mt-4">
                <div className="text-center d-flex align-items-center px-2">
                  <img src={apple} alt="" className="pe-3" />
                  <h3>IOS Development</h3>
                </div>
                <p>
                  Apple changes its craze consistently and we help you out in
                  matching the trends of the market. We have a team of experts
                  to develop applications thereby following the Apple UI
                  guidelines. You can stay updated with your application for the
                  latest adaptation of the iphone and other gadgets of apple.
                </p>
                <div className="text-center d-flex align-items-center px-2">
                  <img src={android} alt="" className="pe-3" />
                  <h3>Android Development</h3>
                </div>
                <p>
                  We create user-friendly android applications that can suit
                  well for your business plan. Powerful tools like Jira, Android
                  Studio, Github, and Jenkins are chosen to craft excellent
                  android applications using Java, and Kotlin Languages.
                </p>

                <div className="d-flex align-items-center px-2">
                  <img src={cloud} alt="" className="pe-3" />
                  <h3>Hybrid App Development</h3>
                </div>
                <p>
                  Our team has successfully built hybrid apps that offer various
                  benefits to its users. The development of hybrid applications
                  includes modern technology and framework to give optimal
                  performance.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4 d-md-block d-none">
            <div className="vis_img">
              <img src={App_development} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appdservices;

import React from "react";
import {
  iot,
  store,
  manufacturing,
  smarthouse,
  heartrate,
} from "../../../assests/img/index";

const Iiotservices = () => {
  return (
    <div>
      <div className="w-90">
        <div className="d-flex flex-wrap align-items-center zer_vis">
          <div className="col-md-6 mt-4 d-md-none d-block">
            <div className="vis_img">
              <img src={iot} alt="" />
            </div>
          </div>
          <div className="col-md-6 px-2">
            <div className="vi_si">
              <h1>Robust Applications of IoT</h1>
              <div className="po_4 mt-2">
                <p>
                  Whether you're aiming for operational excellence or improved
                  customer experiences augmented mixed or virtual realities can
                  make the difference.
                </p>
              </div>
            </div>
            <div className="pro_ser">
              <div className="d-flex flex-wrap mt-4">
                <div className="game_dev text-center col-sm-6 m-auto p-4">
                  <img src={store} alt="" />
                  <h3>Retail</h3>
                </div>
                <div className="e_com text-center col-sm-6 m-auto p-4">
                  <img src={manufacturing} alt="" />
                  <h3>Manufacturing</h3>
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="thr_d text-center col-sm-6 m-auto p-4">
                  <img src={smarthouse} alt="" />
                  <h3>Banking</h3>
                </div>
                <div className="vir_360 text-center col-sm-6 m-auto p-4">
                  <img src={heartrate} alt="" />
                  <h3>Healthcare</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4 d-md-block d-none">
            <div className="vis_img">
              <img src={iot} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Iiotservices;

export const BASE_URL = "https://newsite.zercorp.com/api/";
// "https://newsite.zercorp.com/public/api/";
// "http://192.168.1.202/laravel-basic-crm/public/index.php/api/";
// "http://192.168.1.202/zercorp_backend_crm/public/index.php/api/";
// "http://tsiicscript.abpas.co.in/public/index.php/";
// 'http://192.168.1.202/autometry_scripts/public/';
// 'http://192.168.1.14/autometry_scripts/public/';

export const URL = {
  // LOGIN: "api/user-login",
  // REGISTER: "api/user-register",
  // USER_NAME_CHECK: "api/user-check",
  // ZONE_LIST: "crmapi/placelist",

  GET_PRODUCT: "products",
  PRODUCT_VIEW: "product/view/",
  PRICING_LIST: "pricing",
  CAREERS_LIST: "careers",
  JOBVIEW: "career/view/",

  POST_JOB: "career/form",
  ENQUIRY_FORM: "enquiry",
};

import React from "react";
import { appdev } from "../../../assests/img/index";

const Appdwork = () => {
  return (
    <div>
      <div class="vr_txt w-90 my-5">
        <h1>Exploring the future of mobile app development</h1>
        <p>
          Make your business mobile-friendly and grow it faster. A quality
          mobile application helps in user engagement and builds a powerful
          bridge between the customers and the business. Being a top mobile
          developer, S-One works with the mission of building robust
          applications to serve customer queries digitally. We craft efficient
          applications to match the trends and technology thereby delivering an
          excellent user experience. Growing the business at your fingertips is
          a perfect choice and our powerful applications meet all the guidelines
          in the development phase.
        </p>
      </div>
      <div class="w-90">
        <div class="d-flex flex-wrap align-items-center zer_vis">
          <div class="col-md-6">
            <div class="vis_img">
              <img src={appdev} alt="" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="vi_si">
              <h1>Our work</h1>
              <div class="po_4 d-flex mt-2">
                <p>
                  We build world-class applications to meet the demands of the
                  users and the business process is filled with efficiency,
                  growth, user engagement, sales, and revenue. With the usage of
                  our mobile applications, undoubtedly you will explore a new
                  track to transform your business into a successful one. The
                  business is yours and then we help in transforming the path of
                  your business. We have built around 100+ mobile applications
                  that deliver an outstanding user experience. The innovative
                  software collaboration is implemented in various platforms
                  like iOS, Android, hybrid, and IoT applications.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appdwork;

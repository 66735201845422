import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import ErrorHandler from "../../../Services/Constant/ErrorHandler";
import ApplicationService from "../../../Services/Service/service";
import { contact } from "../../assests/img/index";

const SupportEnquiry = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("message", message);

    if (name && mobile && email && message) {
      ApplicationService.enquiry_form(formData)
        .then((res) => {
          // notify();
          // toast.success("Applied Succesfully");
          toast.success(res.data.message);

          setName("");
          setEmail("");
          setMobile("");
          setMessage("");
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    }
  };
  return (
    <div>
      <ToastContainer autoClose={2000} />
      <div className="ge_fo mt-5">
        <div className="d-flex flex-wrap align-items-center w-90">
          <div className="col-md-7 col-12">
            <div className="ge_tou p-4">
              <h3>Get in touch</h3>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="mb-2 mt-3">
                  <label htmlFor="name">Name</label>
                  <input
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter Name"
                    name="name"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="email">E-mail</label>
                  <input
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter E-mail"
                    name="email"
                  />
                </div>
                <div className="mb-2 mt-3 ">
                  <label htmlFor="">Contact</label>
                  <input
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      if (e.target.value.length !== 10) {
                        e.target.setCustomValidity("invalid Number");
                      } else if (e.target.value.length === 10) {
                        e.target.setCustomValidity("");

                        setMobile(e.target.value);
                      }
                    }}
                    maxlength={10}
                    required
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    type="number"
                    className="form-control"
                    id="mobile"
                    placeholder="Contact Number"
                    name="mobile"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="message">Message</label>
                  <input
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    type="text"
                    className="form-control"
                    id="message"
                    placeholder="Enter your Message"
                    name="message"
                  />
                </div>
                <button type="" className="btn btn-sub" onClick={handleSubmit}>
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-5 con_img m-auto">
            <img src={contact} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportEnquiry;

import React from "react";
//import { helmetJsonLdProp } from "react-schemaorg";
// import { Helmet } from "react-helmet-async";
import { Helmet } from "react-helmet";
//import { MetaTags } from "react-meta-tags";
import Footer from "../../Footer";
import Navbar from "../../Navbar";
import AboutBanner from "./AboutBanner";
import Aboutchoose from "./Aboutchoose";
import AboutCompany from "./AboutCompany";
import Abouttech from "./Abouttech";
import HomeSeo from "../../seo/HomeSeo";
const About = () => {
  //ReactGA.initialize('UA-250225495-1');
    //ReactGA.initialize('G-54EZ0RNFEK');
  return (
    <div>
      {/* <MetaTags>
        <title>abouta</title>
        <meta name="description" content="Some description." />
        <meta name="keyword" content="Some description." />
        <meta property="og:title" content="MyApp" />
        <meta property="og:image" content="path/to/image.jpg" />
      </MetaTags> */}
      <HomeSeo seo_page="about" />
      <Navbar />
      <AboutBanner />
      <AboutCompany />
      <Abouttech />
      <Aboutchoose />
      <Footer />
    </div>
  );
};

export default About;

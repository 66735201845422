import React from "react";
import { onlinee } from "../../../assests/img/index";

const Iiotwork = () => {
  return (
    <div>
      <div className="vr_txt w-90 my-5">
        <h1>Exploring The Future of IoT</h1>
        <p>
          Internet of Things (IoT) services help aggregate data from physical
          objects and smartly use it for process automation and business
          intelligence. At S-One we drive value-centered IoT solutions and set
          up multi-level data pipelines for that from edge computing to cloud
          data processing and data science.
        </p>
      </div>
      <div className="w-90">
        <div className="d-flex flex-wrap align-items-center zer_vis">
          <div className="col-md-6">
            <div className="vis_img">
              <img src={onlinee} alt="" />
              {/* <Online /> */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="vi_si">
              <h1>Our work</h1>
              <div className="po_4 d-flex mt-2">
                <p>
                  The technology of IoT is applied to every industrial domain to
                  turn their ideas into reality. IoT has become popular, and we
                  are doing our best to connect with the latest technology and
                  provide high-quality solutions. Our development team focuses
                  on delivering efficient IoT solutions by utilizing
                  cutting-edge technologies to build an IoT-oriented system or
                  software. With over a year of experience, our experts can meet
                  the needs of our customers by providing excellent IoT
                  Standards, protocols, and cloud platforms. We provide a wide
                  range of solutions, from connected machines to intelligent
                  systems. We help businesses by providing flexible solutions
                  that make their dreams a reality by recreating industrial
                  performance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Iiotwork;

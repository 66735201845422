import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import Footer from "../../../Footer";
import Navbar from "../../../Navbar";
import Crmbanner from "./Crmbanner";
import Crmclient from "./Crmclient";
import Crmcontact from "./Crmcontact";
import Crmservices from "./Crmservices";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga';
import ApplicationService from "../../../../Services/Service/service";
import ErrorHandler from "../../../../Services/Constant/ErrorHandler";
import CrmEnquiry from "./CrmEnquiry";
//import HomeSeo from "../../seo/HomeSeo";
const Crm = () => {
  ReactGA.initialize('UA-250225495-1');
    ReactGA.initialize('G-54EZ0RNFEK');
  const history = useHistory();

  // const navigate = useNavigate();

  const [data, setData] = useState("");

  let location = useLocation();

  const getData = () => {
    // const result = axios.get('http://192.168.1.202/laravel-basic-crm/public/index.php/api/product/view/')
    const result = location.state;
    ApplicationService.productView(result)
      .then((res) => {
        setData(res.data.product);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  useEffect(() => {
    if (location.state) {
      getData();
    } else {
      history.goBack();
      // navigate(-1);
    }
  }, [location.state]);

  return (
    <div>
       <Helmet>
                      <meta name="google-site-verification" content="fZNS7AAagtO7niFxzrtqd_LMZfKex6ZqDpYlpMUnldo" /> 
           
                      <title>Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai,ERP Software Development Company in Chennai</title>
              <meta name="description" content="Zercorp Offers Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai" />
              <link rel="canonical" href="https://zercorp.com/products" />
              <meta property="og:locale" content="en" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content="Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai,ERP Software Development Company in Chennai" />
              <meta property="og:description" content="Zercorp Offers Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai,ERP Software Development Company in Chennai" />
              <meta property="og:url" content="https://zercorp.com/products" />
              <meta property="og:image" content="https://zercorp.com/static/media/logo.c38decb0b4c385708847.png" />
              <meta property="fb:app_id" content="https://www.facebook.com/Zercorp-inc-102804135651058" />
              <meta name="twitter:card" content="summary" />
              <meta name="twitter:site" content="Zercorp" />
              <meta name="twitter:title" content="Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai,ERP Software Development Company in Chennai" />
              <meta name="twitter:description" content="Zercorp Offers Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai,ERP Software Development Company in Chennai" />
              <meta name="twitter:image" content="https://zercorp.com/static/media/logo.c38decb0b4c385708847.png" />
              <meta name="twitter:image:alt" content="Zercorp" />
              <script type="application/ld+json">
                {JSON.stringify({
                    "@context":"http://schema.org",
                    "@type": "WebPage",
                    "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
                    "description": "Zercorp Offers Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai",
                    "url": "https://zercorp.com/products"
                })}
          
            </script>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context":"http://schema.org",
                    "@type": "PostalAddress",
                    "address": {
                        "@type":"PostalAddress",
                        "addressLocality": "D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani, Chennai 600 113.",
                        "addressRegion": "India",
                        "postalCode": "600113",
                        "streetAddress": "D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani, Chennai 600 113."
                },
                    
                    "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
                    "openingHoursSpecification": {
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday"
                    ],
                    "opens": "09:30",
                    "closes": "06:30"
                },
                    "telephone": "044-22548188",
                    "url": "https://zercorp.com/products"
                })}
          
            </script>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context":"http://schema.org",
                    "@type": "Organization",
                    "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
                    "url": "https://zercorp.com/products",
                    "sameAs": [
                        "https://www.facebook.com/Zercorp-inc-102804135651058/",
                        "https://www.linkedin.com/in/zercorp-software-b29508242/"
                    ]
                })}
          
            </script>
          <script type="application/ld+json">
          {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Article",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://zercorp.com/products"
          },
          "headline": "Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai,ERP Software Development Company in Chennai",
          "description": "Zercorp Offers Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai",
          "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
          "author": {
            "@type": "Person",
            "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
            "logo": {
              "@type": "ImageObject",
              "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
            }
          },
          "datePublished": "2020-03-04",
          "dateModified": "2022-01-05"
            })}
          </script>
          
          <script type="application/ld+json">
          {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Article",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://zercorp.com/products"
          },
          "headline": "Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai,ERP Software Development Company in Chennai",
          "description": "Zercorp Offers Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai",
          "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
          "author": {
            "@type": "Organization",
            "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
            "logo": {
              "@type": "ImageObject",
              "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
            }
          },
          "datePublished": "2020-03-04",
          "dateModified": "2022-01-05"}
          )}
          </script>
          
          <script type="application/ld+json">
          {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://zercorp.com/products"
          },
          "headline": "Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai,ERP Software Development Company in Chennai",
          "description": "Zercorp Offers Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai",
          "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
          "author": {
            "@type": "Person",
            "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
            "logo": {
              "@type": "ImageObject",
              "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
            }
          },
          "datePublished": "2020-03-04",
          "dateModified": "2022-01-05"}
          )}
          </script>
          
          <script type="application/ld+json">
          {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://zercorp.com/products"
          },
          "headline": "Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai,ERP Software Development Company in Chennai",
          "description": "Zercorp Offers Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai",
          "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
          "author": {
            "@type": "Organization",
            "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
            "logo": {
              "@type": "ImageObject",
              "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
            }
          },
          "datePublished": "2020-03-04",
          "dateModified": "2022-01-05"}
          )}
          </script>
          
          <script type="application/ld+json">
          {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://zercorp.com/products"
          },
          "headline": "Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai,ERP Software Development Company in Chennai",
          "description": "Zercorp Offers Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai",
          "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
          "author": {
            "@type": "Person",
            "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
            "logo": {
              "@type": "ImageObject",
              "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
            }
          },
          "datePublished": "2020-03-04",
          "dateModified": "2022-01-05"}
          )}
          </script>
          
          <script type="application/ld+json">
          {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://zercorp.com/products"
          },
          "headline": "Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai,ERP Software Development Company in Chennai",
          "description": "Zercorp Offers Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai",
          "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",  
          "author": {
            "@type": "Organization",
            "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
            "logo": {
              "@type": "ImageObject",
              "url": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
            }
          },
          "datePublished": "2020-03-04",
          "dateModified": "2022-01-05"}
          )}
          </script>
          <script type="application/ld+json">
          {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "name": "Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai,ERP Software Development Company in Chennai",
          "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
          "@id": "https://zercorp.com/products",
          "url": "https://zercorp.com/products",
          "telephone": "044-22548188",
          "priceRange": "min-1lakh",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani, Chennai 600 113.",
            "addressLocality": "Chennai",
            "postalCode": "600113",
            "addressCountry": "IN"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 12.800762,
            "longitude": 80.223476
          },
          "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday"
            ],
            "opens": "00:00",
            "closes": "23:59"
          } }
          )}
          </script>
          
          <script type="application/ld+json">
          {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI PVT.LTD",
          "alternateName": "Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai,ERP Software Development Company in Chennai",
          "url": "https://zercorp.com/products",
          "logo": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
          "sameAs": [
            "https://www.facebook.com/Zercorp-inc-102804135651058/",
               "https://www.instagram.com/zercorp.software/",
            "https://www.linkedin.com/in/zercorp-software-b29508242/",
            "https://zercorp.com/products"
          ]}
          )}
          </script>
          
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebSite",
              "url": "https://zercorp.com/products",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://query. zercorp.com /search?q={search_term_string}",
                "query-input": "required name=search_term_string"
              }}
            )}
            </script>
          
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "https://zercorp.com/products",
              "logo": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"}
            )}
            </script>
          
          <script type="application/ld+json">
          {JSON.stringify({
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai,ERP Software Development Company in Chennai",
          "image": "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
          "description": "Zercorp Offers Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai",
          "brand": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
          "sku": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
          "mpn": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
          "gtin14": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
          "gtin13": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
          "gtin8": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
          "offers": {
            "@type": "AggregateOffer",
            "url": "https://zercorp.com/products",
            "priceCurrency": "INR",
            "lowPrice": "100000",
            "highPrice": "100000000000",
            "offerCount": "1"
          },
             "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "5",
            "bestRating": "5",
            "worstRating": "1",
            "ratingCount": "20786"
          },
          "review": [
            {
              "@type": "Review",
              "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
              "datePublished": "2019-04-01",
              "reviewBody": "Zercorp Offers Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai",
              "author": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "1",
                "worstRating": "1"
              }
            },
            {
              "@type": "Review",
              "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
              "datePublished": "2019-03-25",
              "reviewBody": "Zercorp Offers Best CRM for Sales & Marketing in Chennai, CRM software in Chennai, Cloud CRM Software in Chennai, Best CRM software in Chennai for Sales & Marketing, Retail Solution & Technologies in Chennai,Best Retail Management Software in Chennai, Custom Retail Software Solutions in Chennai, Retail Software For Small Business, Retail Software For Small Business in Chennai, Inventory Management Software for Retail in Chennai, POS Inventory Management Software in Chennai,Best HRMS Software System in chennai,HR Payroll Software in Chennai,ERP Software in Chennai, ERP Software Company in Chennai, ERP Software Development Company in Chennai",
              "author": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "4",
                "worstRating": "1"
              }
            }
          ]}
          )}
          </script>
          
          <script type="application/ld+json">
          {JSON.stringify({
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "ZERCORP - CLOUD CRM SOFTWARE DEVELOPMENT COMPANY IN CHENNAI",
          "url": "https://zercorp.com/products",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://zercorp.com/{search_term_string}",
            "query-input": "required name=search_term_string"
          }}
          )}
          </script>
          </Helmet>
      <Navbar />

      {data && <Crmbanner data={data} />}
      {data?.amenities && <Crmservices data={data} />}

      {data?.contents && <Crmcontact data={data} />}
      {/* <Crmclient /> */}
      <CrmEnquiry />
      <Footer />
    </div>
  );
};

export default Crm;

import React, { useState } from "react";
import ErrorHandler from "../../../Services/Constant/ErrorHandler";
import ApplicationService from "../../../Services/Service/service";
import { enquiry } from "../../assests/img/index";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../../Navbar";
import Footer from "../../Footer";

const Proposal = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("message", message);

    if (name && mobile && email && message) {
      ApplicationService.enquiry_form(formData)
        .then((res) => {
          // notify();
          console.log(res);
          // toast.success("Applied Succesfully");
          toast.success(res.data.message);

          setName("");
          setEmail("");
          setMobile("");
          setMessage("");
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    }
  };
  return (
    <div>
      <ToastContainer autoClose={2000} />
      <Navbar />

      <div className="w-90">
        <div className="row align-items-center">
          <div className="col-md-7 ">
            <div className="enq_img">
              <img src={enquiry} alt="" />
            </div>
          </div>
          <div className="col-md-5 col mt-4 ">
            <div className="fo_rm">
              <h1>How can we help you?</h1>
              <p>You want to talk to our Expert to Digitalise your Business</p>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="mb-3 mt-3">
                  <input
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    className="form-enq form-control"
                    placeholder="Enter Name"
                    name="name"
                  />
                </div>
                <div className="mb-3 mt-3">
                  <input
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      if (e.target.value.length !== 10) {
                        e.target.setCustomValidity("invalid Number");
                      } else if (e.target.value.length == 10) {
                        e.target.setCustomValidity("");

                        setMobile(e.target.value);
                      }
                    }}
                    maxlength={10}
                    required
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    type="number"
                    className="form-enq form-control"
                    placeholder="Contact Number"
                    name="mobile"
                  />
                </div>
                <div className="mb-3">
                  <input
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-enq form-control"
                    placeholder="Email ID"
                    name="email"
                  />
                </div>
                <div className="mb-3">
                  <input
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="form-enq form-control"
                    placeholder="Message"
                    name="message"
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-sub"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Proposal;

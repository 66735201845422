import React from "react";
import { iiotbanner } from "../../../assests/img";

const Iiotbanner = () => {
  return (
    <div>
      <div className="digi_pt">
        <div className="col-md-12">
          <div className="dig_img">
            <img src={iiotbanner} alt="" />
          </div>
          <div className="digi_con">
            <h1>Industrial (IOT) Things</h1>
            <p>
              S-One provides the best services for Industrial (IoT)Things. We
              serve various industries in meeting the requirements of the
              customers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Iiotbanner;

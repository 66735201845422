import React from "react";
import { Bannerimg } from "../../assests/img";

const Banner = () => {
  return (
    <div>
      <div className="w-90">
        <div className="ban_ner justify-content-center align-items-center d-flex flex-wrap">
          <div className="cont_ent col-md-6 col-7 px-3 banerss">
            <h1>
              The perfect digital platform to manage business process and
              enhance it
            </h1>
            <p className="d-md-block d-none">
              Zercorp works in delivering solutions to critical digital
              challenges and makes the entire business process automated. We
              transform the way you work with smart applications.
            </p>
          </div>
          <div className="ban_img col-md-6 col-5">
            <img src={Bannerimg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;

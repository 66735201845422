import React from "react";
import { vrcover } from "../../../assests/img/index";

const Vrbanner = () => {
  return (
    <div>
      <div className="digi_pt">
        <div className="col-md-12">
          <div className="dig_img">
            <img src={vrcover} alt="" />
          </div>
          <div className="digi_con">
            <h1>VR / AR Application Development</h1>
            <p>
              S-One is the leading VR/ AR Application Development company. All
              industries from Manufacturing to Media & Entertainment are using
              our services to enrich their customer experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vrbanner;

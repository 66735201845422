import React from "react";
import { cover } from "../../assests/img/index";

const AboutBanner = () => {
  return (
    <div>
      <div className="digi_pt">
        <div className="row">
          <div className="col-md-12">
            <div className="dig_img">
              <img src={cover} alt="" />
            </div>
            <div className="digi_con">
              <h1>YOUR DIGITALISATION PARTNER</h1>
              <p>
                Digitalise your business process through Zercorp. We are
                bringing you the disruptive technologies to enhance your
                business. We are here to bring this transformation to all From
                Startup to Enterprise.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBanner;

import React from "react";
import { virtualR } from "../../../assests/img/index";

const Vrwork = () => {
  return (
    <div>
      <div className="vr_txt w-90 my-5">
        <h1>Let's Explore the virtual world.</h1>
        <p>
          Everything goes on a virtual trend, and it is vital to have a virtual
          reality application for your business. Virtual Reality applications
          deliver a high standard way of representing the business in front of
          many users.VR applications are combined with three-dimensional objects
          to offer the best solutions. Augmented Reality AR includes a
          collaborative environment as well as computer-enhanced visuals that
          simulate reality. Using a high-end AR application, your business can
          enter the market and become a customer favorite.
        </p>
      </div>
      <div className="w-90 ">
        <div className="d-flex flex-wrap align-items-center zer_vis">
          <div className="col-md-6">
            <div className="vis_img">
              <img src={virtualR} alt="" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="vi_si">
              <h1>Our work</h1>
              <div className="po_4 d-flex mt-2">
                <p>
                  The team of S-One is expertized in developing impressive,
                  robust VR/AR applications for various industries like
                  manufacturing, healthcare, media, entertainment, education,
                  sales & marketing. The user can get an adventurous experience
                  by using the application as it is specifically designed to
                  create a real-life sense. What else is needed for a user to
                  gain a delightful experience that matches reality? The VR/AR
                  Application crafted by our team can suit all the business
                  needs. The application is friendly to all platforms like
                  Smartphones (Android & iOS), Laptops, and tablets. Our wide
                  range of applications includes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vrwork;

import React from "react";
import {
  ecommerce,
  gameDevelopment,
  three60,
  threeD,
  vr2,
} from "../../../assests/img/index";

const Vrservices = () => {
  return (
    <div>
      <div className="w-90 ">
        <div className="d-flex flex-wrap align-items-center zer_vis">
          <div className="col-md-6 mt-4 d-md-none d-block">
            <div className="vis_img">
              <img src={vr2} alt="" />
            </div>
          </div>
          <div className="col-md-6 px-2">
            <div className="vi_si">
              <h1>At your services</h1>
              <div className=" mt-2">
                <p>
                  Whether you're aiming for operational excellence or improved
                  customer experiences augmented mixed or virtual realities can
                  make the difference.
                </p>
              </div>
            </div>
            <div className="pro_ser">
              <div className="d-flex flex-wrap mt-4">
                <div className="game_dev text-center col-sm-6 m-auto p-4">
                  <img src={gameDevelopment} alt="" />
                  <h3>Game Development</h3>
                </div>
                <div className="e_com text-center col-sm-6  m-auto p-4">
                  <img src={ecommerce} alt="" />
                  <h3>E-commerce applications</h3>
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="thr_d text-center col-sm-6  m-auto p-4">
                  <img src={threeD} alt="" />
                  <h3>3D Content Design</h3>
                </div>
                <div className="vir_360 text-center col-sm-6  m-auto p-4">
                  <img src={three60} alt="" />
                  <h3>360-degree Virtual tours</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4 d-md-block d-none">
            <div className="vis_img">
              <img src={vr2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vrservices;

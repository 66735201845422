import React from "react";
import { about, Business_vision, star1, tic } from "../../assests/img/index";

const AboutCompany = () => {
  return (
    <div>
      <div className="w-90 py-4">
        <div className="d-flex flex-wrap align-items-center zer_ab">
          <div className="col-md-6 px-2 col-md-none d-block">
            <div className="ab_oimg text-center">
              <img src={about} alt="" />
            </div>
          </div>
          <div className="col-md-6 px-2">
            <div className="ab_ou">
              <h1>About Company</h1>
              {/* <p>
                Zercorp is a leading Software development company provides end
                to end Digital Transformation Solutions. We develop strategic
                software solutions for businesses across the Globe.
              </p> */}
              <div className="po_1 d-flex mt-2">
                <img src={tic} alt="" />
                <p>
                  Zercorp adds value to the business world by developing
                  innovations, advancements, and software that meet all needs.
                  Every business is a platform that provides a variety of
                  opportunities to creators. Without a doubt, the software has
                  become a critical component in the growth of the business, and
                  we develop robust software and applications that serve as the
                  ideal module for the requirements. Zercorp develops technical
                  components to advance various industries such as IT, banking,
                  finance, sales, and marketing.
                </p>
              </div>
              <div className="po_2 d-flex mt-2">
                <img src={tic} alt="" />
                <p>
                  When you choose Zercorp, your company's image to your
                  employees and customers is enhanced. We are confident that
                  once you begin using our products, your business will be on
                  its way to success. Our customers can easily find improvements
                  in their activities as they become more simplified than they
                  could have imagined. A good business requires a quality
                  process that our software can most likely meet. Our finance
                  software can undoubtedly help with complex processes such as
                  sales, marketing, accounting, and people management. Integrate
                  your business and effectively manage it to your fingertips.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 px-2 col-md-block d-none">
            <div className="ab_oimg text-center">
              <img src={about} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="w-90 py-4">
        <div className="d-flex flex-wrap align-items-center zer_vis">
          <div className="col-md-6">
            <div className="vi_si">
              <h1>Vision & Mission</h1>
              <div className="po_3 d-flex mt-2">
                <img src={star1} alt="" />
                <p>
                  Zercorp aspires to create software products with the same
                  zeal. Our team's main motto is
                  <span className="fw-bold">
                    {""} “Reliable software for business needs."
                  </span>
                </p>
              </div>
              <div className="po_4 d-flex mt-2">
                <img src={star1} alt="" />
                <p>
                  We design our software in such a way that it will make our
                  customers happy. Customers will gladly pay for the
                  satisfaction they gain from using our products, allowing us to
                  meet our business objectives. Our dream is to spread joy from
                  creators to customers. Customers are provided with a fantastic
                  opportunity to improve their businesses and pay with complete
                  satisfaction for the high-quality software they use for the
                  business.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="vis_img">
              <img src={Business_vision} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;

import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import { serviceenquiry } from "../../../assests/img/index";
import ApplicationService from "../../../../Services/Service/service";
import ErrorHandler from "../../../../Services/Constant/ErrorHandler";
import { ToastContainer, toast } from "react-toastify";

const CrmEnquiry = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("message", message);

    if (name && mobile && email && message) {
      ApplicationService.enquiry_form(formData)
        .then((res) => {
          // notify();
          // toast.success("Applied Succesfully");
          toast.success(res.data.message);

          setName("");
          setEmail("");
          setMobile("");
          setMessage("");
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    }
  };

  return (
    <div>
      <ToastContainer autoClose={2000} />

      <div className="ou_head text-center">
        <h1>Get in Touch</h1>
        <div className="line_box">
          <div className="lines_box"></div>
        </div>
      </div>
      <div className="d-flex flex-wrap align-items-center crm_enquiry">
        <div
          className="col-md-6 px-3 mt-2 wow fadeInLeft"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <ScrollAnimation
            animateIn="fadeInLeft"
            animateOut="fadeOutRight"
            duration={0.9}
            delay={0.4}
          >
            <div className="enq_img">
              <img src={serviceenquiry} alt="/" />
            </div>
          </ScrollAnimation>
          {/* <div className="enq_img">
            <img src={serviceenquiry} alt="" />
          </div> */}
        </div>
        <div
          className="col-md-6 col form_bg px-3 mt-4 wow fadeInRight"
          data-wow-duration="1s"
          data-wow-delay="0.8s"
        >
          {" "}
          <ScrollAnimation animateIn="fadeInRight" animateOut="fadeOutleft">
            <div className="fo_rm">
              <h1>How can we help you?</h1>
              <p>You want to talk to our Expert to Digitalise your Business</p>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="mb-3 mt-3">
                  <input
                    required
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    type="text"
                    className="form-ser form-control"
                    id="name"
                    placeholder="Name"
                    name="name"
                  />
                </div>
                <div className="mb-3 mt-3">
                  <input
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      if (e.target.value.length !== 10) {
                        e.target.setCustomValidity("invalid Number");
                      } else if (e.target.value.length == 10) {
                        e.target.setCustomValidity("");

                        setMobile(e.target.value);
                      }
                    }}
                    maxlength={10}
                    required
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    type="number"
                    className="form-ser form-control"
                    id="mobile"
                    placeholder="Contact Number"
                    name="mobile"
                  />
                </div>
                <div className="mb-3">
                  <input
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-ser form-control"
                    placeholder="Email"
                    name="email"
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    name="message"
                    id="message"
                    cols="30"
                    rows="5"
                    className="form-control form-ser"
                    placeholder="Message"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn btn-sub"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </form>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
};

export default CrmEnquiry;

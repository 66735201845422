import React from "react";
import { NavLink } from "react-router-dom";
import { pin, star1 } from "../../assests/img/index";
import $ from "jquery";

const Careerchoice = ({ data }) => {
  // console.log(data);
  return (
    <div>
      <div className="car_tit text-center mt-3">
        <h1>It is never too late to be what you might have been</h1>
      </div>
      <div className="d-flex flex-wrap w-90 mt-4">
        {data?.map((list, i) => {
          return (
            <div className="col-md-4 px-3 mt-3" key={i}>
              <div className="jo_b">
                <h3>{list.name}</h3>
                <div className="ex_pe d-flex mt-1">
                  <img src={star1} />
                  <p>{`Experience: ${list.experience}`}</p>
                </div>
                <div className="lo_ca d-flex mt-1">
                  <img src={pin} />
                  <p>{`Location: ${list.location}`}</p>
                </div>
                <p className="al_ign">{list.description}</p>
                <div className="app_ly">
                  <NavLink
                    to={{
                      pathname: "/jobview",
                      state: list.id,
                    }}
                  >
                    <a href="" className="btn bt_btn">
                      Apply now
                    </a>
                  </NavLink>
                </div>
              </div>
            </div>
          );
        })}
        {/* 
        <div className="col-md-4 px-3 mt-3">
          <div className="jo_b">
            <h3>Sales specialist</h3>
            <div className="ex_pe d-flex mt-1">
              <img src={star1} />
              <p>Experience: 0 to 2 years</p>
            </div>
            <div className="lo_ca d-flex mt-1">
              <img src={pin} />
              <p>Location: Tamilnadu</p>
            </div>
            <p className="al_ign">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div className="app_ly">
              <Link to="/jobview">
                <a href="" className="btn bt_btn">
                  Apply now
                </a>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-4 px-3 mt-3">
          <div className="jo_b">
            <h3>Product marketers</h3>
            <div className="ex_pe d-flex mt-1">
              <img src={star1} />
              <p>Experience: 0 to 2 years</p>
            </div>
            <div className="lo_ca d-flex mt-1">
              <img src={pin} />
              <p>Location: Tamilnadu</p>
            </div>
            <p className="al_ign">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div className="app_ly">
              <Link to="/jobview">
                <a href="" className="btn bt_btn">
                  Apply now
                </a>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-md-4 px-3 mt-3">
          <div className="jo_b">
            <h3>Account Executive</h3>
            <div className="ex_pe d-flex mt-1">
              <img src={star1} />
              <p>Experience: 0 to 2 years</p>
            </div>
            <div className="lo_ca d-flex mt-1">
              <img src={pin} />
              <p>Location: Tamilnadu</p>
            </div>
            <p className="al_ign">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div className="app_ly">
              <Link to="/jobview">
                <a href="" className="btn bt_btn">
                  Apply now
                </a>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-4 px-3 mt-3">
          <div className="jo_b">
            <h3>Sales specialist</h3>
            <div className="ex_pe d-flex mt-1">
              <img src={star1} />
              <p>Experience: 0 to 2 years</p>
            </div>
            <div className="lo_ca d-flex mt-1">
              <img src={pin} />
              <p>Location: Tamilnadu</p>
            </div>
            <p className="al_ign">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div className="app_ly">
              <Link to="/jobview">
                <a href="" className="btn bt_btn">
                  Apply now
                </a>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-4 px-3 mt-3">
          <div className="jo_b">
            <h3>Product marketers</h3>
            <div className="ex_pe d-flex mt-1">
              <img src={star1} />
              <p>Experience: 0 to 2 years</p>
            </div>
            <div className="lo_ca d-flex mt-1">
              <img src={pin} />
              <p>Location: Tamilnadu</p>
            </div>
            <p className="al_ign">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div className="app_ly">
              <Link to="/jobview">
                <a href="" className="btn bt_btn">
                  Apply now
                </a>
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Careerchoice;

import React from "react";
import httpClient from "../Constant/httpClient";
import { URL } from "../Constant/URL";

const productList = () => {
  return httpClient.get(URL.GET_PRODUCT);
};
const productView = (name) => {
  return httpClient.get(URL.PRODUCT_VIEW + name);
};

const pricingList = () => {
  return httpClient.get(URL.PRICING_LIST);
};

const careersList = () => {
  return httpClient.get(URL.CAREERS_LIST);
};

const jobview = (role) => {
  return httpClient.get(URL.JOBVIEW + role);
};

const postjob = (applyied) => {
  return httpClient.post(URL.POST_JOB, applyied);
};

const enquiry_form = (data) => {
  return httpClient.post(URL.ENQUIRY_FORM, data);
};

const ApplicationService = {
  productList,
  productView,
  pricingList,
  careersList,
  jobview,
  postjob,
  enquiry_form,
};

export default ApplicationService;

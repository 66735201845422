import React from "react";
import { BiBuildingHouse } from "react-icons/bi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiMail } from "react-icons/hi";
import logoWhite from "../Components/assests/img/logo-white.png";
import facebook from "../Components/assests/img/facebook.png";
import linkedin from "../Components/assests/img/linkedin.png";
import twitter from "../Components/assests/img/twitter.png";
import instagram from "../Components/assests/img/insta.png";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      {/* footer starts  */}
      <div className="fo_oter mt-5">
        <div className="w-90">
          <div className="row ">
            <div className="col-md-4 align-items-center mt-4">
              <div className="lo_go text-center">
                <img src={logoWhite} alt="" />
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-5  mt-4 ">
              <div className="li-st">
                <ul>
                  {/* <li>
                    <Link to="/">
                      <a href="">Products</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <a href="">Services</a>
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/pricing">
                      <a href="">Pricing</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <a href="">About us</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/career">
                      <a href="">Careers</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/support">
                      <a href="contactus.php">Support</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6  col-7  mt-4">
              <div className="gen_ral">
                <div style={{ color: "#fff" }} className="loc_a d-flex mt-2">
                  <BiBuildingHouse size={35} className="me-2" />
                  <p>
                    D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani,
                    Chennai 600 113.
                  </p>
                </div>
                <div style={{ color: "#fff" }} className="pho_e d-flex mt-2">
                  <BsFillTelephoneFill size={20} className="me-2" />

                  <p>+91-8925819914</p>
                  {/* <p className="mx-1">+91 93803 88188</p> */}
                </div>
                <div style={{ color: "#fff" }} className="ma_il d-flex mt-2">
                  <HiMail size={20} className="me-2" />
                  <p>info.zercorp@gmail.com</p>
                </div>
              </div>
            </div>
            <div className="col-md-3  col-sm-3  col-12  mt-4">
              <div className="fol_low text-center ">
                <p>Follow us</p>
                <a
                  href="https://www.facebook.com/Zercorp-inc-102804135651058/?ref=pages_you_manage"
                  target="blank"
                >
                  <img src={facebook} className="me-2" />
                </a>
                <a
                  href="https://www.instagram.com/zercorp.software/"
                  target="blank"
                >
                  <img src={instagram} alt="" className="me-2" />
                </a>
                <a
                  href="https://www.linkedin.com/in/zercorp-software-b29508242/"
                  target="blank"
                >
                  <img src={linkedin} alt="" className="me-2" />
                </a>
              </div>
              <div className="ge_t text-center mb-2">
                <Link to="/enquiry">
                  <a href="" className="btn bt_btn">
                    Get proposal
                  </a>
                </Link>
                {/* <a href="" className="btn bt_btn">
                  Get proposal
                </a> */}
              </div>
            </div>
            <div className="co_py text-center ">
              <p>
                {" "}
                ALL RIGHTS RESERVED BY ZERCORP
                {/* <a href="http://www.ninositsolution.com/" target="_blank">
                  NINOSITSOLUTION{" "}
                </a> */}{" "}
                <a className="d-none">
                  Design & Developed by NINOS IT SOLUTION{" "}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* footer ends    */}
    </div>
  );
};

export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import { image8, laptop } from "../../../assests/img/index";

const Crmcontact = ({ data }) => {
  const contact = data.contents;

  const url = contact[0]?.url;

  // console.log(url);

  // const content = data[0].content;

  // const url = contact[0].url;

  // console.log(contact[0].url);

  return (
    <div>
      {contact.length > 0 &&
        contact?.map((element, index) => {
          return (
            <div
              className={
                index % 2 == 0
                  ? "d-flex flex-wrap  justify-content-center align-items-center w-90   my-md-5 my-3"
                  : "d-flex flex-wrap flex-row-reverse justify-content-center align-items-center w-90   my-md-5 my-3"
              }
            >
              <div className="col-md-6 px-2 mt-md-0 mt-3">
                <div className="crm_det">
                  {/* {content.map((para) => {
                return <p>{para}</p>;
              })} */}
                  <h1>{element?.name}</h1>

                  {element.content.length > 0 &&
                    element.content?.map((para) => {
                      return <p>{para}</p>;
                    })}

                  {/* <p>{element[0].content}</p>
                  {content.length > 0 &&
                    content.map((para) => {
                      return <p>{para}</p>;
                    })} */}

                  {/* <a href={element.url} target="blank">
                  <button>
                    Start 15 days trial{" "}
                    <i className="fa-solid fa-arrow-right-long"></i>
                  </button>
                </a> */}
                  {/* <Link to={`//${element.url}`} target="_blank"> */}
                  <a href={`https://${element?.url}`} target="_blank">
                    <button
                    // onClick={() => {
                    //   window.open("www.copyright.com", "_blank");
                    // }}
                    >
                      Start 15 days trial{" "}
                      <i className="fa-solid fa-arrow-right-long"></i>
                    </button>
                  </a>
                  {/* </Link> */}
                </div>
              </div>

              <div className="col-md-6 px-2  ">
                <div className="crm_img">
                  <img src={element?.image_path} alt="" />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Crmcontact;

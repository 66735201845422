import React, { useState } from "react";
import { BsCheck } from "react-icons/bs";
import { basic, pro } from "../../assests/img/index";

const PricingCard = ({ data }) => {
  // console.log(data);
  return (
    <div>
      <div className="pri_con text-center mt-4">
        <div className="pri_hea">
          <p>Our Pricing Plan</p>
        </div>
        <div className="pri_head">
          <h1>Great Price Plans For You!</h1>
        </div>
        <div className="pri_par">
          <p>Get enterprise-level features at a small business price</p>
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-center w-90 my-5">
        {data?.map((list, i) => {
          return (
            <div className="col-md-6 px-3 mt-3 bas_ic" key={i}>
              <div className="bo_x1">
                <div className="bas_con text-center">
                  <h6>{list.name}</h6>
                  <img src={list.image_path} alt="" />

                  {list.content.map((para, index) => {
                    return (
                      <div className="li_stw " key={index}>
                        <div className="li_1 d-flex  px-2 mt-3">
                          <div>
                            <BsCheck size={25} />
                          </div>
                          <div>
                            <p>{para}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="d-flex flex-wrap align-items-center text-center bu_y">
                  <div className="col-md-6 text-center px-2 mb-2">
                    <div className="st_btn">
                      <a href={list.trial_url} target="_blank">
                        Start Free Trial
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 text-center px-2 mb-2">
                    <div className="pu_btn">
                      <a href={list.purchase_url} target="_blank">
                        Purchase Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {/* <div className="col-md-6 px-3 mt-3 pro_fes">
          <div className="bo_x2">
            <div className="pro_con text-center">
              <h6>Professional Plan</h6>
              <img src={pro} alt="" />
              <div className="li_stw ">
                <div className="li_1 d-flex align-items-center px-2 mt-3">
                  <BsCheck size={25} />
                  <p>All In One Dashboard</p>
                </div>
                <div className="li_1 d-flex align-items-center px-2 mt-3">
                  <BsCheck size={25} />
                  <p>Mobile Application</p>
                </div>
                <div className="li_1 d-flex align-items-center px-2 mt-3">
                  <BsCheck size={25} />
                  <p>Internet Things</p>
                </div>
                <div className="li_1 d-flex align-items-center px-2 mt-3">
                  <BsCheck size={25} />
                  <p>Software Development</p>
                </div>
                <div className="li_1 d-flex align-items-center px-2 mt-3">
                  <BsCheck size={25} />
                  <p>VR Development</p>
                </div>
                <div className="li_1 d-flex align-items-center px-2 my-3">
                  <BsCheck size={25} />
                  <p>AR Development</p>
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap align-items-center text-center bu_y">
              <div className="col-md-6 text-center px-2 mb-2">
                <div className="st_btn">
                  <a href=" ">Start Free Trial</a>
                </div>
              </div>
              <div className="col-md-6 text-center px-2 mb-2">
                <div className="pu_btn">
                  <a href=" ">Purchase Now</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PricingCard;

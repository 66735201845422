import React from "react";
import { FaMapMarkedAlt } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";

const Supportinfo = () => {
  return (
    <div>
      <div className="ge_ts text-center">
        <h1>Get Support</h1>
        <p>
          "We don't want to push our ideas on to customers, we simply want to
          make what they want."
        </p>
      </div>
      <div className="ge_list mt-4">
        <div className="d-flex flex-wrap justify-content-center w-90">
          <div className="col-md-4 px-3">
            <div className="box1 w-100 text-center">
              <div className="icon d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-map-location-dot">
                  <FaMapMarkedAlt />
                </i>
              </div>
              <div className="te_xt">
                <p>
                  <span className="fw-bold">Head Quarters:</span>
                  {""} D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani,
                  Chennai 600 113.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 px-3">
            <a href="tel: 044-22548188 " style={{ color: "#000" }}>
              <div className="box2 w-100 text-center">
                <div className="icon d-flex align-items-center justify-content-center">
                  <i className="fa-solid fa-phone">
                    <BsFillTelephoneFill />
                  </i>
                </div>
                <div className="te_xt">
                  <p>044-22548188</p>
                  {/* <p>+91 93803 88188</p> */}
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4 px-3">
            <div className="box3 w-100 text-center">
              <div className="icon d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-envelope">
                  <GrMail />
                </i>
              </div>
              <div className="te_xt">
                <p>info.zercorp@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Supportinfo;

import React, { useState, useEffect } from "react";
import { asset9 } from "../../../assests/img/index";

const Crmbanner = ({ data }) => {
  return (
    <div>
      <div className="cr_head w-90 mt-3">
        <div className="ban_ner justify-content-center align-items-center d-flex">
          <div className="cont_ent col-md-6 banerss">
            <h1>{data?.heading}</h1>
            <p>{data?.description}</p>
          </div>
          <div className="lap_img col-md-6 ">
            <img className="d-md-block d-none" src={data?.image_path} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crmbanner;

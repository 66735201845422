import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "../../Footer";
import Navbar from "../../Navbar";
import ApplicationService from "../../../Services/Service/service";
import ErrorHandler from "../../../Services/Constant/ErrorHandler";
import { ToastContainer, toast } from "react-toastify";

import $ from "jquery";

const Jobview = () => {
  // const notify = () => toast("Form submitted");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [qualification, setQualification] = useState("");
  const [experience, setExperience] = useState("");
  const [role, setRole] = useState("");
  const [CTC, setCTC] = useState("");
  const [resume, setResume] = useState("");

  const [open, setOpen] = useState(false);

  const [closeModal, setcloseModal] = useState(false);

  let location = useLocation();
  const history = useHistory();

  const [jobview, setJobview] = useState({});

  const getData = () => {
    const view = location.state;

    ApplicationService.jobview(view)
      .then((res) => {
        setJobview(res.data.career);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const contentssss = jobview?.content;
  const keyssss = jobview?.key;
  const wantssss = jobview?.want;

  useEffect(() => {
    if (location.state) {
      getData();
    } else {
      history.goBack();
    }
  }, []);

  const apply = location.state;

  const handleModal = () => {
    setcloseModal(!closeModal);
  };
  const posttoapi = (e) => {
    e.preventDefault();
  };

  const handleSubmit = () => {
    // if ($("#validForm").valid()) {
    //   console.log("ifff");
    // } else {
    //   console.log("else");
    // }
    // console.log("posted");
    let temp = document.getElementById("btnClose");

    // let carrerForm = document.getElementById("validForm");
    // $(document).ready(function() {
    //   $("#basic-form").validate();
    //   });

    // let validforms = $("#validForm").each(function () {
    //   $(this).valid();
    // });

    // var form = $("#validForm").valid();
    // console.log(validforms);

    // $(carrerForm).valid();

    // temp.click();
    // $("[data-dismiss=modal]").trigger({ type: "click" });
    // $("#myModal").hide();
    //$("#myModal").toggle(false);

    //  $("#myModal").show(false);
    // console.log("closed");
    // return;
    const formData = new FormData();
    formData.append("career_id", apply);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobile", contact);
    formData.append("qualification", qualification);
    formData.append("experience", experience);
    formData.append("role", role);
    formData.append("ctc", CTC);
    formData.append("resume", resume);

    // if (validforms) {
    //   ApplicationService.postjob(formData)
    //     .then((res) => {
    //       handleModal();
    //       setOpen(true);
    //       temp.click();
    //       alert(res.data.message);
    //       setName("");
    //       setEmail("");
    //       setContact("");
    //       setQualification("");
    //       setExperience("");
    //       setRole("");
    //       setCTC("");
    //       setResume(null);
    //     })
    //     .catch(function (error) {
    //       ErrorHandler(error);
    //     });
    // } else {
    //   console.log("failed");
    // }

    // $(document).ready(function() {
    //   $("#basic-form").validate();
    //   });

    if (name && contact && resume && email && role && experience) {
      ApplicationService.postjob(formData)
        .then((res) => {
          // notify();
          toast.success("Applied Succesfully");

          handleModal();
          setOpen(true);
          temp.click();
          // alert(res.data.message);
          document.getElementById("myFile").value = "";
          setName("");
          setEmail("");
          setContact("");
          setQualification("");
          setExperience("");
          setRole("");
          setCTC("");
          setResume();
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    }
  };

  // useEffect(() => {
  //   handleSubmit();
  // }, []);

  return (
    <>
      <div>
        <Navbar />
        <ToastContainer autoClose={2000} />

        <div className="job_det">
          <div className="job_head">
            <div className="job_cont">
              <p>Current Openings </p>

              <div className="acc_exe">
                <h1>{jobview?.name}</h1>
                <p>{`Experience: ${jobview?.experience}`}</p>
                <p>{`Location: ${jobview?.location}`}</p>
              </div>
              <div className="acc_app">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#myModal"
                  onClick={handleModal}
                >
                  Apply now
                </button>
              </div>
            </div>
          </div>

          <div className="job_detail job_cont">
            <h3>Job Description</h3>
            <p>{jobview?.description}</p>
            <p>
              Please note that we have requirements for this role in Chennai.
            </p>
            <h3>Responsibilities:</h3>
            <ul>
              {contentssss?.map((contentss, i) => {
                return <li key={i}>{contentss}</li>;
              })}
            </ul>
            <h3>Key Skills</h3>
            <ul>
              {keyssss?.map((keyss, index) => {
                return <li key={index}>{keyss}</li>;
              })}
            </ul>
            <h3>what we need?</h3>
            <ul>
              {wantssss?.map((wantss, inde) => {
                return <li key={inde}>{wantss}</li>;
              })}
            </ul>
          </div>
        </div>

        <div className="vi_ewal text-center">
          <button
            onClick={() => setOpen(true)}
            data-bs-toggle="modal"
            data-bs-target="#myModal"
          >
            Apply now
          </button>
        </div>

        <Footer />
      </div>
      <div
        className="modal job-form"
        // data-bs-backdrop="false"
        id="myModal"
        tabindex="-1"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <h4 className="modal-title mt-3">Application form</h4>
            <button
              id="btnClose"
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
            <form onSubmit={posttoapi} id="validForm">
              {/* <form> */}
              <div className="mb-2 mt-3 px-3">
                <label htmlFor="name">Name</label>

                <input
                  required
                  onChange={(e) => setName(e.target.value)}
                  type="name"
                  className="form-control"
                  id="name"
                  placeholder="Enter Name"
                  name="name"
                  value={name}
                />
              </div>
              <div className="mb-2 px-3">
                <label htmlFor="email">E-mail</label>
                <input
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter the E-mail"
                  name="email"
                  value={email}
                />
              </div>
              <div className="mb-2 mt-3 px-3">
                <label htmlFor="">Mobile Number</label>
                <input
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  required
                  onChange={(e) => setContact(e.target.value)}
                  type="number"
                  className="form-control"
                  id="name"
                  placeholder="Enter Number"
                  name="mobile"
                  value={contact}
                />
              </div>
              <div className="mb-2 px-3">
                <label htmlFor="">Qualification</label>
                <input
                  required
                  onChange={(e) => setQualification(e.target.value)}
                  type="text"
                  className="form-control"
                  id="qualification"
                  placeholder="Enter Qualification"
                  name="qualification"
                  value={qualification}
                />
              </div>
              <div className="mb-2 px-3">
                <label htmlFor="">Year of Experience</label>
                <input
                  required
                  onChange={(e) => setExperience(e.target.value)}
                  type="text"
                  className="form-control"
                  id="experience"
                  placeholder="Enter Year of Experience"
                  name="experience"
                  value={experience}
                />
              </div>
              <div className="mb-2 px-3">
                <label htmlFor="role">Role</label>
                <input
                  required
                  onChange={(e) => setRole(e.target.value)}
                  type="text"
                  className="form-control"
                  id="role"
                  placeholder="Eg. Account Executive"
                  name="role"
                  value={role}
                />
              </div>
              <div className="mb-2 px-3">
                <label htmlFor="email">Expected CTC</label>
                <input
                  required
                  onChange={(e) => setCTC(e.target.value)}
                  type="text"
                  className="form-control"
                  id="ctc"
                  placeholder="Enter Expected CTC"
                  name="ctc"
                  value={CTC}
                />
              </div>
              <div className="mb-2 px-3">
                <label htmlFor="">Upload your resume</label>
                <input
                  accept=".pdf"
                  required
                  onChange={(e) => setResume(e.target.files[0])}
                  type="file"
                  className="form-control"
                  id="myFile"
                  name="filename"
                />
              </div>
              <div className="sub_mit text-center py-2">
                <button
                  onClick={handleSubmit}
                  className="btn btn-sub"
                  // data-bs-toggle="modal"
                >
                  Submit
                </button>
              </div>
              {/* </form> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Jobview;

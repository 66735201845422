import React from "react";
import { carrers } from "../../assests/img/index";

const Careerbanner = () => {
  return (
    <div>
      <div className="w-90 my-5">
        <div className="car_ban justify-content-center align-items-center d-flex">
          <div className="car_con col-md-6">
            <h1>“The future depends on what you do today.”</h1>
            <p>
              We know that finding a meaningful and rewarding career can be a
              long journey. Our goal is to make that process easy for you and to
              create a work environment that's enriching-one that you'll look
              forward to every day.
            </p>
          </div>
          <div className="car_img col-md-6 d-md-block d-none">
            <img src={carrers} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careerbanner;

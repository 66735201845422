import axios from "axios";
import React, { useEffect, useState } from "react";

import ScrollAnimation from "react-animate-on-scroll";
import {
  customform,
  icon,
  responsive,
  dealmanager,
  activitymanager,
  mailcampaign,
  teammanager,
  mailbox,
  customfield,
  knowledge,
} from "../../../assests/img/index";

const Crmservices = ({ data }) => {
  const services = data.amenities;

  return (
    <div>
      {services.length > 0 && (
        <div className="crm_con">
          <div className="d-flex flex-wrap justify-content-center w-90">
            {services?.map((element) => {
              return (
                <div
                  className="col-lg-2 col-md-3 px-2 mt-2 mb-2 text-center con_1"
                  key={element.id}
                >
                  <ScrollAnimation
                    animateIn="bounce"
                    duration={1.0}
                    delay={0.0}
                  >
                    <div className="ser_1">
                      <img src={element.image_path} alt="" />
                      <p>{element.name}</p>
                    </div>
                  </ScrollAnimation>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* <div class="crm_con">
        <div class="d-flex flex-wrap justify-content-center w-90">
          <div class="col-lg-2 col-md-3 px-2 mt-2 mb-2 text-center con_1">
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={0.0}>
              <div class="ser_1">
                <img src={customform} alt="" />
                <p>Customized lead forms</p>
              </div>
            </ScrollAnimation>
          </div>
          <div class="col-lg-2 col-md-3 text-center px-2 mt-2 mb-2 con_1">
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={0.4}>
              <div class="ser_1">
                <img src={icon} alt="" />
                <p>Contact Manager</p>
              </div>
            </ScrollAnimation>
          </div>
          <div class="col-lg-2 col-md-3 text-center px-2 mt-2 mb-2 con_1">
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={0.8}>
              <div class="ser_1">
                <img src={responsive} alt="" />
                <p>Responsive web pages</p>
              </div>
            </ScrollAnimation>
          </div>
          <div class="col-lg-2 col-md-3 text-center px-2 mt-2 mb-2 con_1">
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={1.2}>
              <div class="ser_1">
                <img src={dealmanager} alt="" />
                <p>Deal Manager</p>
              </div>
            </ScrollAnimation>
          </div>
          <div class="col-lg-2 col-md-3 text-center px-2 mt-2 mb-2 con_1">
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={1.6}>
              <div class="ser_1">
                <img src={activitymanager} alt="" />
                <p>Activity Manager</p>
              </div>
            </ScrollAnimation>
          </div>
          <div class="col-lg-2 col-md-3 px-2 mt-2 mb-2 text-center con_1">
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={2.0}>
              <div class="ser_1">
                <img src={mailcampaign} alt="" />
                <p>Mail Campaign</p>
              </div>
            </ScrollAnimation>
          </div>
          <div class="col-lg-2 col-md-3 px-2 mt-2 mb-2 text-center con_1">
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={2.4}>
              <div class="ser_1">
                <img src={teammanager} alt="" />
                <p>Team Manager</p>
              </div>
            </ScrollAnimation>
          </div>
          <div class="col-lg-2 col-md-3 px-2 mt-2 mb-2 text-center con_1">
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={2.8}>
              <div class="ser_1">
                <img src={mailbox} alt="" />
                <p>Mailbox Integration</p>
              </div>
            </ScrollAnimation>
          </div>
          <div class="col-lg-2 col-md-3 px-2 mt-2 mb-2 text-center con_1">
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={3.2}>
              <div class="ser_1">
                <img src={customfield} alt="" />
                <p>Customized fields</p>
              </div>
            </ScrollAnimation>
          </div>
          <div class="col-lg-2 col-md-3 px-2 mt-2 mb-2 text-center con_1">
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={3.6}>
              <div class="ser_1">
                <img src={knowledge} alt="" />
                <p>Knowledge base manager</p>
              </div>
            </ScrollAnimation>
          </div>
          <div class="col-lg-2 col-md-3 px-2 mt-2 mb-2 text-center con_1">
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={4.0}>
              <div class="ser_1">
                <img src={knowledge} alt="" />
                <p>Custom Pipelines</p>
              </div>
            </ScrollAnimation>
          </div>
          <div class="col-lg-2 col-md-3 px-2 mt-2 mb-2 text-center con_1">
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={4.4}>
              <div class="ser_1">
                <img src={knowledge} alt="" />
                <p>Award-Winning Mobile Application</p>
              </div>
            </ScrollAnimation>
          </div>
          <div class="col-lg-2 col-md-3 px-2 mt-2 mb-2 text-center con_1">
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={4.8}>
              <div class="ser_1">
                <img src={knowledge} alt="" />
                <p>Sales Conversion funnel</p>
              </div>
            </ScrollAnimation>
          </div>
          <div class="col-lg-2 col-md-3 px-2 mt-2 mb-2 text-center con_1">
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={5.2}>
              <div class="ser_1">
                <img src={knowledge} alt="" />
                <p>Smart Reports</p>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Crmservices;

import React from "react";
import { signin, logo } from "../../assests/img/index";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
const Signin = () => {
  ReactGA.initialize("UA-250225495-1");
  ReactGA.initialize("G-54EZ0RNFEK");
  return (
    <div>
      <Helmet>
        <meta
          name="google-site-verification"
          content="fZNS7AAagtO7niFxzrtqd_LMZfKex6ZqDpYlpMUnldo"
        />

        <title>
          Sign in - All In One Dashboard, Mobile Application, Internet Things,
          Software Development, VR Development, AR Development
        </title>
        <meta
          name="description"
          content="Zercorp Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development"
        />
        <link rel="canonical" href="https://zercorp.com/signin" />
        <meta property="og:locale" content="en" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development"
        />
        <meta
          property="og:description"
          content="Zercorp Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development"
        />
        <meta property="og:url" content="https://zercorp.com/signin" />
        <meta
          property="og:image"
          content="https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
        />
        <meta
          property="fb:app_id"
          content="https://www.facebook.com/Zercorp-inc-102804135651058"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="Zercorp" />
        <meta
          name="twitter:title"
          content="Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development"
        />
        <meta
          name="twitter:description"
          content="Zercorp Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development"
        />
        <meta
          name="twitter:image"
          content="https://zercorp.com/static/media/logo.c38decb0b4c385708847.png"
        />
        <meta name="twitter:image:alt" content="Zercorp" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            description:
              "Zercorp Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            url: "https://zercorp.com/signin",
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "PostalAddress",
            address: {
              "@type": "PostalAddress",
              addressLocality:
                "D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani, Chennai 600 113.",
              addressRegion: "India",
              postalCode: "600113",
              streetAddress:
                "D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani, Chennai 600 113.",
            },

            name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            openingHoursSpecification: {
              "@type": "OpeningHoursSpecification",
              dayOfWeek: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ],
              opens: "09:30",
              closes: "06:30",
            },
            telephone: "044-22548188",
            url: "https://zercorp.com/signin",
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            url: "https://zercorp.com/signin",
            sameAs: [
              "https://www.facebook.com/Zercorp-inc-102804135651058/",
              "https://www.linkedin.com/in/zercorp-software-b29508242/",
            ],
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://zercorp.com/signin",
            },
            headline:
              "Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            description:
              "Zercorp Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            image:
              "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
            author: {
              "@type": "Person",
              name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            },
            publisher: {
              "@type": "Organization",
              name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
              logo: {
                "@type": "ImageObject",
                url: "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
              },
            },
            datePublished: "2020-03-04",
            dateModified: "2022-01-05",
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://zercorp.com/signin",
            },
            headline:
              "Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            description:
              "Zercorp Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            image:
              "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
            author: {
              "@type": "Organization",
              name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            },
            publisher: {
              "@type": "Organization",
              name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
              logo: {
                "@type": "ImageObject",
                url: "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
              },
            },
            datePublished: "2020-03-04",
            dateModified: "2022-01-05",
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://zercorp.com/signin",
            },
            headline:
              "Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            description:
              "Zercorp Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            image:
              "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
            author: {
              "@type": "Person",
              name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            },
            publisher: {
              "@type": "Organization",
              name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
              logo: {
                "@type": "ImageObject",
                url: "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
              },
            },
            datePublished: "2020-03-04",
            dateModified: "2022-01-05",
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://zercorp.com/signin",
            },
            headline:
              "Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            description:
              "Zercorp Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            image:
              "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
            author: {
              "@type": "Organization",
              name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            },
            publisher: {
              "@type": "Organization",
              name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
              logo: {
                "@type": "ImageObject",
                url: "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
              },
            },
            datePublished: "2020-03-04",
            dateModified: "2022-01-05",
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://zercorp.com/signin",
            },
            headline:
              "Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            description:
              "Zercorp Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            image:
              "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
            author: {
              "@type": "Person",
              name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            },
            publisher: {
              "@type": "Organization",
              name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
              logo: {
                "@type": "ImageObject",
                url: "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
              },
            },
            datePublished: "2020-03-04",
            dateModified: "2022-01-05",
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://zercorp.com/signin",
            },
            headline:
              "Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            description:
              "Zercorp Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            image:
              "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
            author: {
              "@type": "Organization",
              name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            },
            publisher: {
              "@type": "Organization",
              name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
              logo: {
                "@type": "ImageObject",
                url: "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
              },
            },
            datePublished: "2020-03-04",
            dateModified: "2022-01-05",
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            name: "Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            image:
              "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
            "@id": "https://zercorp.com/signin",
            url: "https://zercorp.com/signin",
            telephone: "044-22548188",
            priceRange: "min-1lakh",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "D Block, Tidel Park, #4, Rajiv Gandhi Salai, Taramani, Chennai 600 113.",
              addressLocality: "Chennai",
              postalCode: "600113",
              addressCountry: "IN",
            },
            geo: {
              "@type": "GeoCoordinates",
              latitude: 12.800762,
              longitude: 80.223476,
            },
            openingHoursSpecification: {
              "@type": "OpeningHoursSpecification",
              dayOfWeek: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ],
              opens: "00:00",
              closes: "23:59",
            },
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            alternateName:
              "Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            url: "https://zercorp.com/signin",
            logo: "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
            sameAs: [
              "https://www.facebook.com/Zercorp-inc-102804135651058/",
              "https://www.instagram.com/zercorp.software/",
              "https://www.linkedin.com/in/zercorp-software-b29508242/",
              "https://zercorp.com/signin",
            ],
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            url: "https://zercorp.com/signin",
            potentialAction: {
              "@type": "SearchAction",
              target:
                "https://query. zercorp.com /search?q={search_term_string}",
              "query-input": "required name=search_term_string",
            },
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://zercorp.com/signin",
            logo: "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: "Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            image:
              "https://zercorp.com/static/media/logo.c38decb0b4c385708847.png",
            description:
              "Zercorp Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
            brand: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            sku: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            mpn: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            gtin14: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            gtin13: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            gtin8: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            offers: {
              "@type": "AggregateOffer",
              url: "https://zercorp.com/signin",
              priceCurrency: "INR",
              lowPrice: "100000",
              highPrice: "100000000000",
              offerCount: "1",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "5",
              bestRating: "5",
              worstRating: "1",
              ratingCount: "20786",
            },
            review: [
              {
                "@type": "Review",
                name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
                datePublished: "2019-04-01",
                reviewBody:
                  "Zercorp Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
                author: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
                reviewRating: {
                  "@type": "Rating",
                  bestRating: "5",
                  ratingValue: "1",
                  worstRating: "1",
                },
              },
              {
                "@type": "Review",
                name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
                datePublished: "2019-03-25",
                reviewBody:
                  "Zercorp Sign in - All In One Dashboard,  Mobile Application, Internet Things, Software Development, VR Development, AR Development",
                author: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
                reviewRating: {
                  "@type": "Rating",
                  bestRating: "5",
                  ratingValue: "4",
                  worstRating: "1",
                },
              },
            ],
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "WebSite",
            name: "ZERCORP - MOBILE APP DEVELOPMENT COMPANY IN CHENNAI",
            url: "https://zercorp.com/signin",
            potentialAction: {
              "@type": "SearchAction",
              target: "https://zercorp.com/{search_term_string}",
              "query-input": "required name=search_term_string",
            },
          })}
        </script>
      </Helmet>
      <Link to="/">
        <img
          src={logo}
          alt="Image"
          className="signin_page"
          // width="140"
          height="70"
        />
      </Link>

      <div className="sub_ject w-90 my-5">
        <div className="d-flex flex-wrap align-items-center">
          <div className="col-md-6 px-3 m-auto">
            <img src={signin} alt="Image" />
          </div>
          <form action="">
            <div className="col-md-6 col-12 px-3">
              <div className="si_gn si_tx">
                <h3>Sign In</h3>
                <div className="us_er">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                  />
                </div>
                <div className="pas_wrd rounded">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                  />
                </div>
                <div className="rem_ber text-end">
                  <a href="forget.php">Forget password?</a>
                </div>
                <div className="lo_sub text-center">
                  <button className="btn btn-dark" type="submit">
                    Continue
                  </button>
                </div>
              </div>
              <div className="al_re" style={{ zIndex: 2 }}>
                New to Zercorp?
                <Link to="/signup">
                  <a href="">
                    <b>
                      <u>Sign-up</u>
                    </b>
                  </a>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signin;

import React, { createContext, useState } from "react";
import { HashRouter, Switch, Route, BrowserRouter  } from "react-router-dom";

import Navbar from "../Navbar";
import Banner from "../index";
import Footer from "../Footer";
// import Pricing from "../pricing";
// import About from "../About";
// import Career from "../Career";
// import Contactus from "../Contactus";
// import Signin from "../Signin";
// import Signup from "../Signup";
// import Crm from "../Crm";
// import Iott from "../iott";
// import Vr from "../Vr";
// import Appde from "../appde";
import Home from "../Pages/home/Home";
import Crm from "../Pages/products/Crm/Crm";
import Iiot from "../Pages/services/iiot/iiot";
import VrAr from "../Pages/services/vr-ar/vr";
import AppDevelopment from "../Pages/services/app-development/appDevelopment";
import Pricing from "../Pages/Pricing/Pricing";
import About from "../Pages/About/About";
import Career from "../Pages/Career/Career";
import Support from "../Pages/Support/Support";
import Signin from "../Pages/Signin/Signin";
import Signup from "../Pages/Signup/Signup";
import Jobview from "../Pages/Career/Jobview";
import Enquiry from "../Pages/home/Enquiry";
import Proposal from "../Pages/home/Proposal";
export const ProductsContext = createContext();
const Routes = () => {
  const [productList, setProductList] = useState([]);
  const items = JSON.parse(localStorage.getItem("products"));

  // localStorage.getItem()
  return (
    <ProductsContext.Provider
      value={{ productList: items, setProductList: setProductList }}
    >
      <BrowserRouter>
        {/* <React.Fragment> */}
        <Switch>
          {/* <Navbar /> */}
          {/* <Route exact path="/" component={Navbar} /> */}
          <Route exact path="/" component={Home} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/about" component={About} />
          <Route path="/career" component={Career} />
          <Route path="/support" component={Support} />
          <Route path="/signin" component={Signin} />
          <Route path="/signup" component={Signup} />
          <Route path="/products" component={Crm} />
          <Route path="/iot-developent-company-in-chennai" component={Iiot} />
          <Route path="/virtual-reality-augument-reality-developent-company-in-chennai" component={VrAr} />
          <Route path="/mobile-app-developent-company-in-chennai" component={AppDevelopment} />
          <Route path="/jobview" component={Jobview} />
          <Route path="/enquiry" component={Proposal} />

          {/* <Footer /> */}
        </Switch>

        {/* </React.Fragment> */}
      </BrowserRouter>
    </ProductsContext.Provider>
  );
};

export default Routes;

export const Bannerimg = require("../img/Asset 1.jpg");
export const computer = require("../img/computer.png");
export const phone = require("../img/phone.png");
export const cloud1 = require("../img/cloud1.png");
export const img2 = require("../img/img2.png");
export const img3 = require("../img/img3.png");

export const crm = require("../img/crm.png");
export const finance = require("../img/finance.png");
export const hrms = require("../img/hrms.png");
export const emporium = require("../img/emporium.png");
export const report = require("../img/report.png");
export const q1 = require("../img/q-1.png");
export const iiott = require("../img/iiott.png");
export const q2 = require("../img/q-2.png");
export const mobile = require("../img/mobile.png");
export const vr = require("../img/vr.png");
export const enquiry = require("../img/enquiry.png");
export const asset9 = require("../img/asset-9.png");
export const customform = require("../img/customform.png");
export const responsive = require("../img/responsive.png");
export const dealmanager = require("../img/dealmanager.png");
export const activitymanager = require("../img/activity-manager.png");
export const mailcampaign = require("../img/mailcampaign.png");
export const teammanager = require("../img/teammanager.png");
export const mailbox = require("../img/mailbox.png");
export const customfield = require("../img/customfield.png");
export const knowledge = require("../img/knowledge.png");
export const laptop = require("../img/laptop.png");
export const image8 = require("../img/image8.jpg");
export const icon = require("../img/icon.png");
export const pic1 = require("../img/pic1.png");
export const pic2 = require("../img/pic2.png");
export const pic3 = require("../img/pic3.png");
export const iiotbanner = require("../img/iot-banner.jpg");
export const onlinee = require("../img/online.png");
export const iot = require("../img/iot.jpg");
export const store = require("../img/store.png");
export const manufacturing = require("../img/manufacturing.png");
export const smarthouse = require("../img/smart-house.png");
export const heartrate = require("../img/heart-rate.png");
export const serviceenquiry = require("../img/serviceenquiry.png");
export const vrcover = require("../img/vrcover.jpg");
export const vr2 = require("../img/vr2.png");
export const gameDevelopment = require("../img/game-development.png");
export const ecommerce = require("../img/ecommerce.png");
export const threeD = require("../img/3d.png");
export const three60 = require("../img/360.png");
export const virtualR = require("../img/virtualreality.png");
export const mobile_banner = require("../img/mobile-banner.jpg");
export const appdev = require("../img/appdev.jpg");
export const App_development = require("../img/App-development-amico2.png");
export const apple = require("../img/apple.png");
export const android = require("../img/android.png");
export const cloud = require("../img/cloud.png");
export const basic = require("../img/basic.png");
export const pro = require("../img/pro.png");
export const cover = require("../img/cover.jpg");
export const about = require("../img/about.png");
export const tic = require("../img/tic.png");
export const Business_vision = require("../img/Business-vision-amico.png");
export const star1 = require("../img/star1.png");
export const bpm = require("../img/bpm.png");
export const iiot = require("../img/iiot.png");
export const cloudcom = require("../img/cloudcom.png");
export const ai = require("../img/ai-ml.png");
export const ar_vr = require("../img/ar-vr.png");
export const idea = require("../img/idea.png");
export const rating = require("../img/rating.png");
export const ach = require("../img/ach.png");
export const happy = require("../img/happy.png");
export const process = require("../img/process.png");
export const carrers = require("../img/career.png");
export const pin = require("../img/pin.png");
export const contact = require("../img/contact.png");
export const signin = require("../img/signin.png");
export const logo = require("../img/logo.png");
export const asset7 = require("../img/asset 7.png");

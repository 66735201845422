import React from "react";
// import { img2 } from "../../assests/img";
import { img3 } from "../../assests/img";

const Sone = () => {
  return (
    <div>
      <div className="se_onese">
        <div className="w-90 ">
          <div className="d-flex flex-wrap align-items-center">
            <div
              className="col-md-6  d-flex justify-content-center"
              // style={{ margin: "0 auto" }}
            >
              <div className="im_ge d-flex justify-content-center seonsess">
                <img src={img3} alt="" />
              </div>
            </div>
            <div className="col-md-6 sq_uar px-2 ">
              <div className="te_xt">
                <h1>S one Digitalise</h1>
                <h2>Your business with Seamless Platform</h2>
                <p>
                  Zercorp is a leading global provider of end-to-end software
                  and cloud technology solutions. It enables commercial,
                  technology and digital transformations using IP and
                  technology-driven services. Clients can modernize applications
                  and migrate critical workloads on public clouds while
                  optimizing their related software and cloud assets and
                  licensing in parallel.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="si_sq"></div>
      </div>
    </div>
  );
};

export default Sone;

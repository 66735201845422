import React from "react";
import { crm, finance, hrms, emporium, report } from "../../assests/img/index";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Products = () => {
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    items: 3,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <div>
      <div className="my-3 w-90 ser_vice">
        <div className="ou_head text-center">
          <h1>Our Products</h1>
          <div className="line_box">
            <div className="lines_box"></div>
          </div>
        </div>
        <OwlCarousel className="owl-theme owl-carousel owl-theme" {...options}>
          <div className="item">
            <div className="col_one ">
              <div className="ser_vic1">
                <div className="cr_ms">
                  <img src={crm} alt="" />
                </div>
              </div>
              <h5>CRM</h5>
              <p>
                Our Customer Relationship Management Software provides you with
                a one-of-a-kind and seamless experience while also assisting in
                the development of stronger relationships by providing detailed
                information, tracking sales, and identifying opportunities.
                Improve customer relationships and collaborations to increase
                revenue and sales.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="col_one ">
              <div className="ser_vic2">
                <div className="fi_na">
                  <img src={finance} alt="" />
                </div>
              </div>
              <h5>Teams</h5>
              <p>
                Make the payroll process faster with a built-in tax calculating
                framework. Recruit employees and grant them easy access to their
                profile portal for downloading payslips, attendance, leaves, and
                all other activities.
              </p>
            </div>
          </div>

          <div className="item">
            <div className="col_one ">
              <div className="ser_vic3">
                <div className="hr_ms">
                  <img src={hrms} alt="" />
                </div>
              </div>
              <h5>Smart Ledger</h5>
              <p>
                The Smart Ledger from S-One is a powerful financial platform to
                take care of all of the finance and accounting operations. The
                module does more like managing projects, core accounting,
                automating workflows, and stock training.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="col_one ">
              <div className="ser_vic4">
                <div className="em_po">
                  <img src={emporium} alt="" />
                </div>
              </div>
              <h5>Emporium</h5>
              <p>
                Create an elegant digital store online with our professional
                website builder. The code-free design uses the drag-and-drop
                website builder to build the online store and list your
                products.{" "}
              </p>
            </div>
          </div>
          <div className="item">
            <div className="col_one ">
              <div className="ser_vic5">
                <div className="re_po">
                  <img src={report} alt="" />
                </div>
              </div>
              <h5>Report Generation</h5>
              <p>
                Represent the business data as a detailed report. Use
                illustrators, circle graphs, pie charts, bar charts, and other
                tools to create a visual presentation of your business process.
                Define the data categories you want to represent.
              </p>
            </div>
          </div>
        </OwlCarousel>
        {/* <div className="owl-carousel owl-theme"></div> */}
      </div>
    </div>
  );
};

export default Products;

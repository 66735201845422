import React from "react";
import { mobile_banner } from "../../../assests/img/index";

const Appdbanner = () => {
  return (
    <div>
      <div className="digi_pt">
        <div className="col-md-12">
          <div className="dig_img">
            <img src={mobile_banner} alt="" />
          </div>
          <div className="digi_con">
            <h1>Mobile App Development</h1>
            <p>
              We build quality high-end applications to deliver the best
              solutions in a business. Our team builds a great bridge between
              the customers and the technology.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appdbanner;

import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { ai, ar_vr, bpm, cloudcom, iiot } from "../../assests/img/index";

const Abouttech = () => {
  return (
    <div>
      <div className="tec_n">
        <h1>Technology we use</h1>
        <div className="d-flex flex-wrap justify-content-center text-center px-3 te_ch">
          <div
            className="col px-3 wow bounce"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          >
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={0.4}>
              <div className="bus_pro">
                <div className="ic_on1">
                  <img src={bpm} alt="" />
                </div>
                <p>Business Process Management</p>
              </div>
            </ScrollAnimation>
          </div>
          <div
            className="col px-3 wow bounce"
            data-wow-duration="1s"
            data-wow-delay="0.8s"
          >
            {" "}
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={0.8}>
              <div className="iio_t">
                <div className="ic_on2">
                  <img src={iiot} alt="" />
                </div>
                <p>IIOT</p>
              </div>
            </ScrollAnimation>
          </div>
          <div
            className="col px-3 wow bounce"
            data-wow-duration="1s"
            data-wow-delay="1.2s"
          >
            {" "}
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={1.2}>
              <div className="clo_ud">
                <div className="ic_on3">
                  <img src={cloudcom} alt="" />
                </div>
                <p>Cloud Computing</p>
              </div>
            </ScrollAnimation>
          </div>
          <div
            className="col px-3 wow bounce"
            data-wow-duration="1s"
            data-wow-delay="1.6s"
          >
            {" "}
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={1.6}>
              <div className="al_ml">
                <div className="ic_on4">
                  <img src={ai} alt="" />
                </div>
                <p>AI/ML</p>
              </div>
            </ScrollAnimation>
          </div>
          <div
            className="col px-3 wow bounce"
            data-wow-duration="1s"
            data-wow-delay="2.0s"
          >
            {" "}
            <ScrollAnimation animateIn="bounce" duration={1.0} delay={2.0}>
              <div className="ar_vr">
                <div className="ic_on5">
                  <img src={ar_vr} alt="" />
                </div>
                <p>AR VR</p>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Abouttech;

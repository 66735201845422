import React from "react";
import { q1, iiott, q2, mobile, vr } from "../../assests/img/index";

const Services = () => {
  return (
    <div>
      <div className="w-90 my-3">
        <div className="ou_head text-center">
          <h1>Our Services</h1>
          <div className="line_box">
            <div className="lines_box"></div>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center">
          <div className="col-md-4 cir_le2 px-3 ">
            <div className="si_cir2"></div>
            <div className="cap_tion">
              <p>
                Zercorp work with you to solve your critical challenges and help
                you to win your goals. We deliver solutions that accelerate
                transformation of your Business. The solutions we offer falls in
                three main categories.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 px-3 sr_vi">
            <div className="col-md-12 mb-5">
              <div className="box_ba">
                <div className="box_bar">
                  <div className="q_cirt">
                    <img src={q1} alt="" />
                  </div>
                  <div className="cir_cle1">
                    <div className="ii_ot">
                      <img src={iiott} alt="" />
                    </div>
                  </div>
                  <h5>Industrial Internet of Things (IOT)</h5>
                  <p>
                    We Specialise in the Industrial Internet of Things (IoT) and
                    offer a wide range of solutions from Connected Machines to
                    Smart Intelligent systems.
                  </p>
                  <div className="q_cirb">
                    <img src={q2} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12   mb-5 sr_vi">
              <div className="box_ba2 ">
                <div className="box_bar">
                  <div className="q_cirt">
                    <img src={q1} alt="" />
                  </div>
                  <div className="cir_cle2">
                    <div className="mob_ile">
                      <img src={mobile} alt="" />
                    </div>
                  </div>
                  <h5>Custom Application Development</h5>
                  <p>
                    We can develop custom applications on various platforms like
                    IOS, React Native, and Android. We also perform testing
                    services to validate your products for quality.
                  </p>
                  <div className="q_cirb">
                    <img src={q2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-6  px-3 mb-4 sr_vi">
            <div className="box_ba3">
              <div className="box_bar">
                <div className="q_cirt">
                  <img src={q1} alt="" />
                </div>
                <div className="cir_cle3">
                  <div className="vr_ar">
                    <img src={vr} alt="" />
                  </div>
                </div>
                <h5>VR & AR Development</h5>
                <p>
                  We Specialise in Unity & Unreal Application Development. Our
                  service offering includes VR/ AR app development for
                  Manufacturing industries, Healthcare, IT, Education, Media,
                  and Entertainment.
                </p>
                <div className="q_cirb">
                  <img src={q2} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;

import React from "react";

import { computer, phone, cloud1 } from "../../assests/img/index";

const Whatcan = () => {
  return (
    <div>
      <div className="w-90">
        <div className="row my-5  align-items-center">
          <div className="col-md-4 cir_le">
            <div className="si_cir"></div>
            <div className="soft_ne">
              <h1>
                <span style={{ color: "#318be4" }}>Software</span>
                <br />
                for all your needs
              </h1>
            </div>
          </div>
          <div className="col-md-8">
            <div className="wh_at">
              <h2>What can we do</h2>
              <p>
                Zercorp works in delivering solutions to critical digital
                challenges and makes the entire business process automated. We
                transform the way you work with high-level business
                applications.
              </p>
              <div className="row text-center">
                <div className="col-md-4 col-sm-6 ">
                  <div className="po_pu">
                    <img src={computer} alt="" />
                    <h6 className="mt-2">Desktop Solutions</h6>
                    <div className="hov_eff">
                      <p>
                        We are expertized in delivering customized internet
                        software that can bring the power of the web into work.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="po_pu">
                    <div className="img_ta">
                      <img src={phone} alt="" />
                      <h6 className="mt-2">Mobile Solutions</h6>
                    </div>
                    <div className="hov_eff">
                      <p>
                        Mobile devices have altered the way businesses operate.
                        Potential mobile solutions
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 ">
                  <div className="po_pu">
                    <img src={cloud1} alt="" />
                    <h6 className="mt-2">Cloud Solutions</h6>
                    <div className="hov_eff">
                      <p>
                        We introduce our cloud solutions to create a hybrid
                        cloud model that increases productivity and creates
                        better growth opportunities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whatcan;
